function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}
import store from "../../store";
export default {
  namespaced: true,
  state: {
    navMenuItems: [],
  },
  getters: {
    
    getNavMenuItems: (state) => state.navMenuItems,
    getUser(state, getters, rootState, rootGetters) {
      return rootGetters["appData/getUser"];
    },
  },
  mutations: {
    setNavMenuItems(state, UserData) {
      state.navMenuItems = [];

      
      if (UserData.permissions.hasOwnProperty("create_feedback")) {
        state.navMenuItems.push({
          title: "Create Feedback",
          route: "FeedbackLanding",
          icon: "EditIcon",
        });
      }

      if (UserData.permissions.hasOwnProperty("show_feedback")) {
        state.navMenuItems.push({
          title: "Feedback Summary",
          route: "FeedbackSummary",
          icon: "FilterIcon",
        });
      }

      if (UserData.permissions.hasOwnProperty("read_feedback")) {
        state.navMenuItems.push({
          title: "All Feedbacks",
          route: "Feedbacks",
          icon: "ListIcon",
        });
      }

      
    },
  },
  actions: {
    setNavMenuItems({ commit, getters }) {
      const UserData = getters.getUser;
      commit("setNavMenuItems", UserData);
    },
  },
};
