import axiosIns from "@/libs/axios";
function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}
export default {
  namespaced: true,
  state: {
    navMenuItems: [],
  },
  getters: {
    getNavMenuItems: (state) => state.navMenuItems,
  },
  mutations: {
    setNavMenuItems(state) {
      state.navMenuItems = [];

      state.navMenuItems.push({
        title: "Dashboard",
        route: "ShuhadaDashboard",
        icon: "HomeIcon",
      });
      state.navMenuItems.push({
        title: "Role",
        route: "Roles",
        icon: "UserIcon",
      });
      state.navMenuItems.push({
        title: "User",
        route: "Users",
        icon: "UsersIcon",
      });
      state.navMenuItems.sort(GetSortOrder("title"));
    },
  },
  actions: {
    setNavMenuItems({ commit }) {
      commit("setNavMenuItems");
    },
  },
};
