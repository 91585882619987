import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "",
      name: "Home",
      component: () => import("@/views/home/HomeNew.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
    },
    // {
    //   path: "/hh",
    //   name: "",
    //   component: () => import("@/views/home/Home3.vue"),
    //   meta: {
    //     layout: "full",
    //   },
    // },
    {
      path: "",
      name: "ContactUs",
      component: () => import("@/components/contactUs/contactUs.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: () => import("@/components/home/PrivacyPolicy.vue"),
      meta: {
        layout: "full",
        // requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "Login",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/ag",
      name: "Admin",
      component: () => import("@/views/admin/AdminAgDashboard.vue"),
      meta: {
        layout: "full",
        // requiresAuth: false,
        // permission: ["admin", "afp_dashboard_show"]
      },
    },
    {
      path: "/multiple-dashboard-landing-page",
      name: "LandingPage",
      component: () => import("@/views/admin/LandingPage.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
        permission: ["show_multiple_dashboard_landing_page"]
      },
    },
    {
      path: "/agrm-login",
      name: "BookingLogin",
      component: () => import("@/views/auth/BookingLogin.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/login-mh",
      name: "LoginMh",
      component: () => import("@/views/auth/LoginMh.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "",
      name: "Register",
      component: () => import("@/views/auth/Register.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "",
      name: "NewRegister",
      component: () => import("@/views/auth/NewRegister.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/feedback/booking/room/:id",
      name: "BookingFeedback",
      component: () =>
        import("@/components/booking/feedback/Feedback.vue"),
      meta: {
        layout: "full",
        requiresAuth: false,
        // permission: "dashboard_show",
      },
    },
    {
      path: "/feedback/:id",
      name: "Feedback",
      component: () => import("@/views/feedback/Feedback.vue"),
      meta: {
        layout: "full",
      },
    },

    // Admin
    {
      path: "",
      component: () => import("@/views/admin/Home.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "NewDashboard",
          component: () => import("@/views/admin/NewDashboard.vue"),
          meta: {
            layout: "full",
            requiresAuth: true,
            permission: ["admin", "afp_dashboard_show"]
          },
        },

        {
          path: "",
          name: "AdminDashboard",
          component: () => import("@/views/admin/Dashboard.vue"),
          meta: {
            pageTitle: "Dashboard",
            breadcrumb: [
              {
                text: "Dashboard",
                active: true,
              },
            ],
            requiresAuth: true,
            permission: "admin",
          },
        },
        {
          path: "",
          name: "ApproveUser",
          component: () => import("@/views/admin/ApproveUsers.vue"),
          meta: {
            pageTitle: "Approve Users",
            breadcrumb: [
              {
                text: "Approve Users",
              },
            ],
            requiresAuth: true,
            permission: "approve_user_show",
          },
        },
        {
          path: "",
          name: "PETITION",
          component: () => import("@/views/admin/Petition.vue"),
          meta: {
            pageTitle: "Petiton",
            breadcrumb: [
              {
                text: "Petition",
              },
            ],
            requiresAuth: true,
            permission: "petition_show",
          },
        },
        {
          path: "",
          name: "NOC",
          component: () => import("@/views/admin/Noc.vue"),
          meta: {
            pageTitle: "NOC",
            breadcrumb: [
              {
                text: "NOC",
                active: true,
              },
            ],
            requiresAuth: true,
          },
        },
        {
          path: "JobNoc",
          name: "JobNoc",
          component: () => import("@/views/admin/ReEmploymentJobNoc.vue"),
          meta: {
            pageTitle: "NOC",
            breadcrumb: [
              {
                text: "NOC",
                active: true,
              },
            ],
            requiresAuth: true,
          },
        },
        {
          path: "",
          name: "eNOC",
          component: () => import("@/views/admin/E-Noc.vue"),
          meta: {
            pageTitle: "NOC",
            breadcrumb: [
              {
                text: "NOC",
                active: true,
              },
            ],
            requiresAuth: true,
          },
        },
        {
          path: "",
          name: "PS",
          component: () => import("@/views/admin/PsDteNoc.vue"),
          meta: {
            pageTitle: "PS Dte",
            breadcrumb: [
              {
                text: "NOC",
                active: true,
              },
            ],
            requiresAuth: true,
            permission: "show_upload_cnic",
          },
        },

        {
          path: "",
          name: "UserCnicList",
          component: () => import("@/views/admin/UploadCnicToChangeUserType.vue"),
          meta: {
            pageTitle: "User Cnic List",
            breadcrumb: [
              {
                text: "Cnic List",
                active: true,
              },
            ],
            requiresAuth: true,
            permission: "show_change_user_type_to_retired",
          },
        },

        {
          path: "",
          name: "WnR",
          component: () => import("@/views/admin/WnRNoc.vue"),
          meta: {
            pageTitle: "W & R Dte",
            breadcrumb: [
              {
                text: "NOC",
                active: true,
              },
            ],
            requiresAuth: true,
            permission: "show_wrdte",
          },
        },
        {
          path: "/users",
          name: "User",
          component: () => import("@/views/admin/User.vue"),
          meta: {
            pageTitle: "User",
            breadcrumb: [
              {
                text: "User",
              },
            ],
            requiresAuth: true,
            permission: "user_show",
          },
        },
        {
          path: "",
          name: "Profile",
          component: () => import("@/views/admin/Profile.vue"),
          meta: {
            pageTitle: "Profile",
            breadcrumb: [
              {
                text: "Profile",
              },
            ],
            requiresAuth: true,
          },
        },

        {
          path: "/approve",
          name: "Approve",
          component: () => import("@/views/admin/ApproveUsers.vue"),
          meta: {
            pageTitle: "Users",
            breadcrumb: [
              {
                text: "Approve Users",
              },
            ],
            requiresAuth: true,
          },
        },
        {
          path: "",
          name: "Role",
          component: () => import("@/views/admin/Role.vue"),
          meta: {
            pageTitle: "Role",
            breadcrumb: [
              {
                text: "Role",
              },
            ],
            requiresAuth: true,
          },
        },

        // Complaints
        {
          path: "",
          name: "Complaints",
          component: () => import("@/views/admin/Complaint.vue"),
          meta: {
            pageTitle: "Complaints",
            // breadcrumb: [
            //   {
            //     text: "Complaints",
            //     active: true,
            //   },
            // ],
            requiresAuth: true,
            permission: "show_complaint",
          },
        },
        {
          path: "",
          name: "MyComplaints",
          component: () => import("@/views/admin/MyComplaint.vue"),
          meta: {
            pageTitle: "My Complaints",
            // breadcrumb: [
            //   {
            //     text: "My Complaints",
            //     active: true,
            //   },
            // ],
            requiresAuth: true,
            permission: "show_complaint",
          },
        },

        // Bookings
        {
          path: "",
          name: "AGMDashboard",
          component: () => import("@/views/booking/admin/AGMDashboard.vue"),
          meta: {
            pageTitle: "Dashboard",
            requiresAuth: true,
            permission: "agm_dashboard_show",
          },
        },
        {
          path: "ms-dashboard",
          name: "MessSecretaryDashboard",
          component: () => import("@/views/booking/admin/MessSecretaryDashboard.vue"),
          meta: {
            pageTitle: "Dashboard",
            requiresAuth: true,
            permission: "ms_dashboard_show",
          },
        },
        {
          path: "",
          name: "ManagingHqDashboard",
          component: () => import("@/views/booking/admin/ManagingHqDashboard.vue"),
          meta: {
            pageTitle: "Dashboard",
            requiresAuth: true,
            permission: "fmn_dashboard_show",
          },
        },
        {
          path: "",
          name: "BookingUser",
          component: () => import("@/views/booking/admin/BookingUser.vue"),
          meta: {
            pageTitle: "AGRM Users",
            requiresAuth: true,
            permission: "booking_user_show",
          },
        },
        {
          path: "",
          name: "BlockBookingUser",
          component: () => import("@/views/booking/admin/BlockUser.vue"),
          meta: {
            pageTitle: "Users",
            requiresAuth: true,
            permission: "booking_user_show",
          },
        },
        {
          path: "",
          name: "Booking",
          component: () => import("@/views/booking/admin/Booking.vue"),
          meta: {
            pageTitle: "Bookings",
            requiresAuth: true,
            permission: "booking_show",
          },
        },
        {
          path: "",
          name: "MyBooking",
          component: () => import("@/views/booking/admin/MyBooking.vue"),
          meta: {
            pageTitle: "My Bookings",
            requiresAuth: true,
            permission: "booking_show",
          },
        },
        // {
        //   path: "hotels",
        // name: "Hotel",
        // component: () => import("@/views/booking/admin/Hotel.vue"),
        // meta: {
        // pageTitle: "Hotels",
        // requiresAuth: true,
        // permission: "hotel_show",
        // },
        // },
        {
          path: "",
          name: "Mess",
          component: () => import("@/views/booking/admin/Mess.vue"),
          meta: {
            pageTitle: "Messes",
            requiresAuth: true,
            permission: "mess_show",
          },
        },

        {
          path: "",
          name: "Bills",
          component: () => import("@/views/booking/admin/Bills"),
          meta: {
            pageTitle: "Bills",
            requiresAuth: true,
            permission: "booking_show",
          },
        },
        {
          path: "",
          name: "Room",
          component: () => import("@/views/booking/admin/Room.vue"),
          meta: {
            pageTitle: "Rooms",
            requiresAuth: true,
            permission: "room_show",
          },
        },
        {
          path: "",
          name: "GlobalPolicy",
          component: () => import("@/views/booking/admin/GlobalPolicy.vue"),
          meta: {
            pageTitle: "GlobalPolicies",
            requiresAuth: true,
            permission: "global_policy_show",
          },
        },
        {
          path: "",
          name: "Announcement",
          component: () => import("@/views/booking/admin/Announcement.vue"),
          meta: {
            pageTitle: "Announcements",
            requiresAuth: true,
            permission: "announcement_show",
          },
        },
        {
          path: "",
          name: "RoomType",
          component: () => import("@/views/booking/admin/RoomType.vue"),
          meta: {
            pageTitle: "RoomTypes",
            requiresAuth: true,
            permission: "room_type_show",
          },
        },
        {
          path: "",
          name: "ManagingHq",
          component: () => import("@/views/booking/admin/ManagingHq.vue"),
          meta: {
            pageTitle: "Managing HQs",
            requiresAuth: true,
            permission: "managing_hq_show",
          },
        },
        {
          path: "",
          name: "MessSecretary",
          component: () => import("@/views/booking/admin/MessSecretary.vue"),
          meta: {
            pageTitle: "Mess Secretaries",
            requiresAuth: true,
            permission: "mess_secretary_show",
          },
        },
        {
          path: "",
          name: "MessReceptionist",
          component: () => import("@/views/booking/admin/Clerk.vue"),
          meta: {
            pageTitle: "Mess Receptionist",
            requiresAuth: true,
            permission: "clerk_show",
          },
        },
        {
          path: "",
          name: "Facility",
          component: () => import("@/views/booking/admin/Facility.vue"),
          meta: {
            pageTitle: "Facilities",
            requiresAuth: true,
            permission: "facility_show",
          },
        },
        {
          path: "",
          name: "City",
          component: () => import("@/views/booking/admin/City.vue"),
          meta: {
            pageTitle: "Cities",
            requiresAuth: true,
            permission: "city_show",
          },
        },
        {
          path: "",
          name: "CityCategory",
          component: () => import("@/views/booking/admin/CityCategory.vue"),
          meta: {
            pageTitle: "City Categories",
            requiresAuth: true,
            permission: "city_category_show",
          },
        },
        {
          path: "",
          name: "FinancialCheckoutReport",
          component: () => import("@/views/booking/admin/FinancialCheckoutReport.vue"),
          meta: {
            pageTitle: "Financial Checkout Report (Pending)",
            requiresAuth: true,
            permission: "financial_checkout_report_show",
          },
        },
        {
          path: "",
          name: "FinancialCheckoutReportResolved",
          component: () => import("@/views/booking/admin/FinancialCheckoutReportResolved.vue"),
          meta: {
            pageTitle: "Financial Checkout Report (Resolved)",
            requiresAuth: true,
            permission: "financial_checkout_report_show",
          },
        },
        {
          path: "",
          name: "GuestCancellationReport",
          component: () => import("@/views/booking/admin/GuestCancellationReport.vue"),
          meta: {
            pageTitle: "Guest Cancellation Report (Pending)",
            requiresAuth: true,
            permission: "guest_cancellation_report_show",
          },
        },
        {
          path: "",
          name: "GuestCancellationReportRefunded",
          component: () => import("@/views/booking/admin/GuestCancellationReportRefunded.vue"),
          meta: {
            pageTitle: "Guest Cancellation Report (Refunded)",
            requiresAuth: true,
            permission: "guest_cancellation_report_show",
          },
        },
        {
          path: "",
          name: "EarlyCheckoutMessReport",
          component: () => import("@/views/booking/admin/EarlyCheckoutMessReport.vue"),
          meta: {
            pageTitle: "Early Checkout Mess Report (Pending)",
            requiresAuth: true,
            permission: "early_checkout_mess_report_show",
          },
        },
        {
          path: "",
          name: "EarlyCheckoutMessReportResolved",
          component: () => import("@/views/booking/admin/EarlyCheckoutMessReportResolved.vue"),
          meta: {
            pageTitle: "Early Checkout Mess Report (Resolved)",
            requiresAuth: true,
            permission: "early_checkout_mess_report_show",
          },
        },
        {
          path: "",
          name: "EarlyCheckoutGuestReport",
          component: () => import("@/views/booking/admin/EarlyCheckoutGuestReport.vue"),
          meta: {
            pageTitle: "Early Checkout Guest Report (Pending)",
            requiresAuth: true,
            permission: "early_checkout_guest_report_show",
          },
        },
        {
          path: "",
          name: "EarlyCheckoutGuestReportRefunded",
          component: () => import("@/views/booking/admin/EarlyCheckoutGuestReportRefunded.vue"),
          meta: {
            pageTitle: "Early Checkout Guest Report (Refunded)",
            requiresAuth: true,
            permission: "early_checkout_guest_report_show",
          },
        },
        {
          path: "",
          name: "AvailableRooms",
          component: () => import("@/views/booking/admin/AvailableRooms.vue"),
          meta: {
            pageTitle: "Available Rooms",
            requiresAuth: true,
            permission: "available_rooms_show",
          },
        },
        {
          path: "",
          name: "RoomFeedback",
          component: () => import("@/views/booking/admin/RoomFeedback.vue"),
          meta: {
            pageTitle: "Room Feedback",
            requiresAuth: true,
            permission: "room_feedback_show",
          },
        },
        // {
        //   path: "forced-cancellation-report",
        //   name: "ForcedCancellationReport",
        //   component: () => import("@/views/booking/admin/ForcedCancellationReport.vue"),
        //   meta: {
        //     pageTitle: "Forced Cancellation Report",
        //     requiresAuth: true,
        //     permission: "forced_cancellation_report_show",
        //   },
        // },
        {
          path: "",
          name: "Module",
          component: () => import("@/views/admin/Module.vue"),
          meta: {
            pageTitle: "Modules",
            requiresAuth: true,
            permission: "module_show",
          },
        },
        {
          path: "",
          name: "RoomBookingUnavailable",
          component: () => import("@/views/booking/admin/RoomUnavailableForBooking.vue"),
          meta: {
            pageTitle: "Make Rooms Unavailable (For Booking)",
            requiresAuth: true,
            permission: "room_unavailability_date_show",
          },
        },
        {
          path: "",
          name: "MessBookingUnavailable",
          component: () => import("@/views/booking/admin/MessUnavailableForBooking.vue"),
          meta: {
            pageTitle: "Make Messes Unavailable (For Booking)",
            requiresAuth: true,
            permission: "mess_unavailability_date_show",
          },
        },
        {
          path: "",
          name: "CityBookingUnavailable",
          component: () => import("@/views/booking/admin/CityUnavailableForBooking.vue"),
          meta: {
            pageTitle: "Make Cities Unavailable (For Booking)",
            requiresAuth: true,
            permission: "city_unavailability_date_show",
          },
        },
        {
          path: "*",
          redirect: "/",
        },
      ],
    },
    // Feedback
    {
      path: "/feedback",
      component: () => import("@/views/feedback/Home.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
      children: [
        //feedback
        {
          path: "/feedbacklanding",
          name: "FeedbackLanding",
          component: () => import("@/views/feedback/FeedbackLanding.vue"),
          meta: {
            pageTitle: "Feedback",
            breadcrumb: [
              {
                text: "Feedback Landing",
              },
            ],
            requiresAuth: true,
            permission: "create_feedback",
          },
        },

        {
          path: "/summary",
          name: "FeedbackSummary",
          component: () => import("@/views/feedback/FeedbackSummary.vue"),
          meta: {
            pageTitle: "Feedback",
            breadcrumb: [
              {
                text: "Summary",
              },
            ],
            requiresAuth: true,
            permission: "read_feedback",
          },
        },
        {
          path: "/list",
          name: "Feedbacks",
          component: () => import("@/views/feedback/FeedbackList.vue"),
          meta: {
            pageTitle: "Feedbacks",
            breadcrumb: [
              {
                text: "All",
              },
            ],
            requiresAuth: true,
            permission: "show_feedback",
          },
        },
        {
          path: "*",
          redirect: "/",
        },
      ],
    },
    // Petitions
    {
      path: "",
      component: () => import("@/views/admin/Home.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "Petitions",
          component: () => import("@/views/admin/Petition.vue"),
          meta: {
            pageTitle: "Petition",
            breadcrumb: [
              {
                text: "Petition",
              },
            ],
            requiresAuth: true,
            permission: "petition_show",
          },
        },
        {
          path: "*",
          redirect: "/",
        },
      ],
    },
    // Complaint
    {
      path: "",
      component: () => import("@/views/admin/Home.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "Complaint",
          component: () => import("@/views/admin/MyComplaint.vue"),
          meta: {
            pageTitle: "Complaints",
            breadcrumb: [
              {
                text: "My Complaints",
                active: true,
              },
            ],
            requiresAuth: true,
            permission: "show_complaint",
          },
        },
        {
          path: "*",
          redirect: "/",
        },
      ],
    },

    // Shuhada
    {
      path: "",
      component: () => import("@/views/shuhada/Home.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "ShuhadaDashboard",
          component: () => import("@/views/shuhada/Dashboard.vue"),
          meta: {
            pageTitle: "Dashboard",
            breadcrumb: [
              {
                text: "Dashboard",
                active: true,
              },
            ],
            requiresAuth: true,
          },
        },

        {
          path: "*",
          redirect: "dashboard",
        },
      ],
    },
    // Noc
    {
      path: "",
      component: () => import("@/views/admin/Home.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "Nocs",
          component: () => import("@/views/admin/Noc.vue"),
          meta: {
            pageTitle: "NOC",
            breadcrumb: [
              {
                text: "NOC",
                active: true,
              },
            ],
            requiresAuth: true,
            permission: "petition_show",
          },
        },
        {
          path: "*",
          redirect: "/",
        },
      ],
    },
    // PMS

    {
      path: "",
      name: "PMS",
      meta: {
        layout: "full",
        requiresAuth: false,
      },
      beforeEnter() {
        window.open("https://fpms.afp.gov.pk/", "_blank");
      },
    },
    {
      path: "",
      name: "CureMe",
      meta: {
        layout: "full",
        requiresAuth: false,
      },
      beforeEnter() {
        window.open("https://bit.ly/CureMe_Patient", "_blank");
      },
    },
    {
      path: "",
      name: "AMS",
      meta: {
        layout: "full",
        requiresAuth: false,
      },
      beforeEnter() {
        window.open("https://ams.afp.gov.pk/", "_blank");
      },
    },
    {
      path: "",
      name: "ACM",
      meta: {
        layout: "full",
        requiresAuth: false,
      },
      beforeEnter() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
          // Redirect to Android app or Play Store
          window.open("https://play.google.com/store/apps/details?id=com.YasoobConsulting.ACM&pli=1", "_blank");
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // Redirect to iOS app or App Store
          window.open("https://apps.apple.com/pk/app/acm-complaint-portal/id1671769558", "_blank");
        } else {
          // Redirect to website for desktop users
          window.open("https://play.google.com/store/apps/details?id=com.YasoobConsulting.ACM&pli=1", "_blank");
        }
      },
    },

    {
      path: "",
      // name: "Home",
      component: () => import("@/views/booking/home/Home.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
        // permission: "dashboard_show",
      },
      children: [
        {
          path: "",
          name: "BookingHome",
          component: () => import("@/components/booking/home/Body.vue"),
          meta: {
            layout: "full",
            requiresAuth: true,
            // permission: "dashboard_show",
            user_types_allowed: [1, 2],
            roles_allowed: ["su", "nu"],
            block_field: "bms_blocked"
          },
        },
        // {
        //   path: "/hotel",
        // name: "Hotel",
        // component: () => import("@/views/booking/hotel/Hotel.vue"),
        // meta: {
        // layout: "full",
        // requiresAuth: true,
        // permission: "hotel_show",
        // },
        // },
        {
          path: "",
          name: "GuestHouses",
          component: () =>
            import("@/components/booking/guesthouses/GuestHouses.vue"),
          meta: {
            layout: "full",
            requiresAuth: true,
            // permission: "dashboard_show",
            user_types_allowed: [1, 2],
            roles_allowed: ["su", "nu"],
            block_field: "bms_blocked"
          },
        },
        {
          path: "",
          name: "GuestHouse",
          component: () =>
            import("@/components/booking/guesthouses/GuestHouse.vue"),
          meta: {
            layout: "full",
            requiresAuth: true,
            // permission: "dashboard_show",
            user_types_allowed: [1, 2],
            roles_allowed: ["su", "nu"],
            block_field: "bms_blocked"
          },
        },
        {
          path: "",
          name: "BookingHistory",
          component: () =>
            import("@/components/booking/history/BookingHistory.vue"),
          meta: {
            layout: "full",
            requiresAuth: true,
            // permission: "dashboard_show",
            user_types_allowed: [1, 2],
            roles_allowed: ["su", "nu"],
            block_field: "bms_blocked"
          },
        },
      ],
    },

    {
      path: "*",
      redirect: "/",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
