import Vue from "vue";

import axios from "axios";

const axiosIns2 = axios.create({
  baseURL: process.env.VUE_APP_API,
});

const COOKIE_EXPIRED_MSG = "Token is invalid or expired";

axiosIns2.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let error_message = null;
    if (error.response.data.messages) {
      error_message = error.response.data.messages[0].message;
    }
    switch (error.response.status) {
      case 401:
        if (!error.config.retry && error_message === COOKIE_EXPIRED_MSG) {
          error.config.retry = true;
          
          // axiosIns.defaults.xsrfCookieName = "csrf_refresh_token";
          // await axiosIns.post("/refresh");
          // axiosIns.defaults.xsrfCookieName = "csrf_access_token";
          
          store.commit("appConfig/UPDATE_SKIN", "light");
          store.dispatch("appData/logoutUnAuth");
          router.push({ name: "Login" });
          return axiosIns(error.config);
        } else {
          throw new Error("Error");
        }
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns2;

export default axiosIns2;
