<template>
  <div>
    <p id="watermark">{{ fullTextWatermark }}</p>
  </div>
</template>

<script>
export default {
  props: {
    textWatermark: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fullTextWatermark: "",
      n: 10000,
    };
  },
  mounted() {
    this.generateWatermark();
  },
  methods: {
    generateWatermark() {
      for (let i = 0; i < this.n; i++) {
        this.fullTextWatermark += " " + this.textWatermark;
      }
    },
  },
};
</script>

<style scoped>
#watermark {
  color: rgba(128, 128, 128, 0.3);
  height: 200%;
  left: 0;
  line-height: 5;
  margin: 0;
  position: fixed;
  top: 0;
  transform: rotate(-30deg);
  transform-origin: 0 100%;
  opacity: 0.5;
  width: 200%;
  word-spacing: 15px;
  z-index: 9999;
  pointer-events: none;
  /* -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;  */
}
</style>
