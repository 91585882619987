function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}
import store from "../../store";
export default {
  namespaced: true,
  state: {
    navMenuItems: [],
  },
  getters: {
    getNavMenuItems: (state) => state.navMenuItems,
    getUser(state, getters, rootState, rootGetters) {
      return rootGetters["appData/getUser"];
    },
  },
  mutations: {
    setNavMenuItems(state, UserData) {
      state.navMenuItems = [];
      // let bookingRoles = [
      //   'bk__agm',
      //   'bk__mess_sec',
      //   'bk__fmn_adm'
      // ]

      if (UserData.user_type !== 1) {
        if (UserData.permissions.hasOwnProperty("petition_show")) {
          state.navMenuItems.push({
            title: "Petition",
            route: "PETITION",
            icon: "AirplayIcon",
          });
        }
        if (UserData.permissions.hasOwnProperty("petition_create")) {
          state.navMenuItems.push({
            title: "NOC",
            route: "NOC",
            icon: "AirplayIcon",
          });
        }
        if (UserData.permissions.hasOwnProperty("petition_create") && UserData.role_data.code_name === "su") {
          state.navMenuItems.push({
            title: "E-NOC",
            route: "eNOC",
            icon: "AirplayIcon",
          });
        }

        if (UserData.permissions.hasOwnProperty("show_job_noc")) {
          state.navMenuItems.push({
            title: "Re-employment NOC",
            route: "JobNoc",
            icon: "AirplayIcon",
          });
        }
      }

      // if (UserData.permissions.hasOwnProperty("show_complaint")) {
      //   state.navMenuItems.push({
      //     title: "My Complaints",
      //     icon: "ClipboardIcon",
      //     route: "MyComplaints",
      //   });
      // }

      // if (UserData.permissions.hasOwnProperty("show_complaint") && UserData.role_data.code_name === "su" || UserData.role_data.code_name === "op") {
      //   state.navMenuItems.push({
      //     title: "Complaints",
      //     icon: "ClipboardIcon",
      //     route: "Complaints",
      //   });
      // }

      if (UserData.permissions.hasOwnProperty("show_upload_cnic") || UserData.role_data.code_name === "su") {
        state.navMenuItems.push({
          title: "PS Dte",
          icon: "ClipboardIcon",
          route: "PS",
        });
      }

      if (UserData.permissions.hasOwnProperty("show_change_user_type_to_retired") || UserData.role_data.code_name === "su") {
        state.navMenuItems.push({
          title: "Change User Type to Retire",
          icon: "ClipboardIcon",
          route: "UserCnicList",
        });
      }

      // if (UserData.permissions.hasOwnProperty("show_wrdte") || UserData.role_data.code_name === "su") {
      //   state.navMenuItems.push({
      //     title: "W & R Dte",
      //     icon: "ClipboardIcon",
      //     route: "WnR",
      //   });
      // }

      if (UserData.permissions.hasOwnProperty("approve_user_show")) {
        state.navMenuItems.push({
          title: "Approve Users",
          route: "Approve",
          icon: "UsersIcon",
        });
      }

      if (UserData.permissions.hasOwnProperty("user_show")) {
        state.navMenuItems.push({
          title: "User",
          route: "User",
          icon: "UserIcon",
        });
      }

      if (UserData.role_data.code_name === "su") {
        state.navMenuItems.push({
          title: "Role",
          route: "Role",
          icon: "UsersIcon",
        });
        state.navMenuItems.push({
          title: "Module",
          route: "Module",
          icon: "GridIcon",
        });
      }

      if (UserData.role_data.code_name === "su" || UserData.role_data.code_name.includes('bk__')) {
        const bookingMenuItem = {
          title: "AGRM",
          icon: "ClipboardIcon",
          children: [],
        };
        state.navMenuItems.push(bookingMenuItem);
        if (UserData.permissions.hasOwnProperty("agm_dashboard_show")) {
          bookingMenuItem.children.push({
            title: "AGM Dashboard",
            route: "AGMDashboard",
          });
        }
        if (UserData.permissions.hasOwnProperty("fmn_dashboard_show")) {
          bookingMenuItem.children.push({
            title: "Managing HQ Dashboard",
            route: "ManagingHqDashboard",
          });
        }
        if (UserData.permissions.hasOwnProperty("ms_dashboard_show")) {
          bookingMenuItem.children.push({
            title: "Mess Secretary Dashboard",
            route: "MessSecretaryDashboard",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("booking_show")
        ) {
          bookingMenuItem.children.push({
            title: "Booking",
            route: "Booking",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("booking_show")
        ) {
          bookingMenuItem.children.push({
            title: "My Bookings",
            route: "MyBooking",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("available_rooms_show")
        ) {
          bookingMenuItem.children.push({
            title: "Available Rooms",
            route: "AvailableRooms",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("room_feedback_show")
        ) {
          bookingMenuItem.children.push({
            title: "Room Feedback",
            route: "RoomFeedback",
          });
        }
        // if (
        //   UserData.permissions.hasOwnProperty("mess_show")
        // ) {
        // if (
        //   UserData.permissions.hasOwnProperty("booking_show")
        // ) {
        //   bookingMenuItem.children.push({
        //     title: "Bills",
        //     route: "Bills",
        //   });
        // }
        if (
          UserData.permissions.hasOwnProperty("mess_show")
        ) {
          bookingMenuItem.children.push({
            title: "Mess",
            route: "Mess",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("mess_unavailability_date_show")
        ) {
          bookingMenuItem.children.push({
            title: "Make Mess Unavailable (For Booking)",
            route: "MessBookingUnavailable",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("mess_secretary_show")
        ) {
          bookingMenuItem.children.push({
            title: "Mess Secretary",
            route: "MessSecretary",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("clerk_show")
        ) {
          bookingMenuItem.children.push({
            title: "Mess Receptionist",
            route: "MessReceptionist",
          });
        }
        // if (
        // UserData.permissions.hasOwnProperty("hotel_show")
        // ) {
        // bookingMenuItem.children.push({
        // title: "Hotel",
        // icon: "BookOpenIcon",
        // route: "Hotel",
        // });
        // }
        if (
          UserData.permissions.hasOwnProperty("room_show")
        ) {
          bookingMenuItem.children.push({
            title: "Room",
            route: "Room",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("room_unavailability_date_show")
        ) {
          bookingMenuItem.children.push({
            title: "Make Room Unavailable (For Booking)",
            route: "RoomBookingUnavailable",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("global_policy_show")
        ) {
          bookingMenuItem.children.push({
            title: "Global Policy",
            route: "GlobalPolicy",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("announcement_show")
        ) {
          bookingMenuItem.children.push({
            title: "Announcement",
            route: "Announcement",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("managing_hq_show")
        ) {
          bookingMenuItem.children.push({
            title: "Managing HQ",
            route: "ManagingHq",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("room_type_show")
        ) {
          bookingMenuItem.children.push({
            title: "Room Type",
            route: "RoomType",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("facility_show")
        ) {
          bookingMenuItem.children.push({
            title: "Facility",
            route: "Facility",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("city_show")
        ) {
          bookingMenuItem.children.push({
            title: "City",
            route: "City",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("city_unavailability_date_show")
        ) {
          bookingMenuItem.children.push({
            title: "Make City Unavailable (For Booking)",
            route: "CityBookingUnavailable",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("city_category_show")
        ) {
          bookingMenuItem.children.push({
            title: "City Category",
            route: "CityCategory",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("booking_user_show")
        ) {
          bookingMenuItem.children.push({
            title: "AGRM Users",
            route: "BookingUser",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("booking_user_show")
        ) {
          bookingMenuItem.children.push({
            title: "Users",
            route: "BlockBookingUser",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("financial_checkout_report_show")
        ) {
          bookingMenuItem.children.push({
            title: "Financial Checkout Report (Pending)",
            route: "FinancialCheckoutReport",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("financial_checkout_report_show")
        ) {
          bookingMenuItem.children.push({
            title: "Financial Checkout Report (Resolved)",
            route: "FinancialCheckoutReportResolved",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("guest_cancellation_report_show")
        ) {
          bookingMenuItem.children.push({
            title: "Guest Cancellation Report (Pending)",
            route: "GuestCancellationReport",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("guest_cancellation_report_show")
        ) {
          bookingMenuItem.children.push({
            title: "Guest Cancellation Report (Refunded)",
            route: "GuestCancellationReportRefunded",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("early_checkout_mess_report_show")
        ) {
          bookingMenuItem.children.push({
            title: "Early Checkout Mess Report (Pending)",
            route: "EarlyCheckoutMessReport",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("early_checkout_mess_report_show")
        ) {
          bookingMenuItem.children.push({
            title: "Early Checkout Mess Report (Resolved)",
            route: "EarlyCheckoutMessReportResolved",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("early_checkout_guest_report_show")
        ) {
          bookingMenuItem.children.push({
            title: "Early Checkout Guest Report (Pending)",
            route: "EarlyCheckoutGuestReport",
          });
        }
        if (
          UserData.permissions.hasOwnProperty("early_checkout_guest_report_show")
        ) {
          bookingMenuItem.children.push({
            title: "Early Checkout Guest Report (Refunded)",
            route: "EarlyCheckoutGuestReportRefunded",
          });
        }
        // if (
        //   UserData.permissions.hasOwnProperty("forced_cancellation_report_show")
        // ) {
        //   bookingMenuItem.children.push({
        //     title: "Forced Cancellation Report",
        //     route: "ForcedCancellationReport",
        //   });
        // }
      }


      state.navMenuItems.sort(GetSortOrder("title"));


      if (UserData.permissions.hasOwnProperty("admin")) {
        state.navMenuItems.unshift({
          title: "Download Applications",
          route: "AdminDashboard",
          icon: "DownloadIcon",
        });
      }

      if (UserData.permissions.hasOwnProperty("admin") || UserData.role_data.code_name == 'bk__agm') {
        state.navMenuItems.unshift({
          title: "Dashboard",
          route: "NewDashboard",
          icon: "MonitorIcon",
        });
      }


    },
  },
  actions: {
    setNavMenuItems({ commit, getters }) {
      const UserData = getters.getUser;
      commit("setNavMenuItems", UserData);
    },
  },
};
