import Vue from "vue";
import Vuex from "vuex";

import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import appData from "./app-data";
import appPetition from "./app-petition";
import appShuhada from "./app-shuhada";
import appNoc from "./app-noc";
import appAdmin from "./app-admin";
import appComplaint from "./app-complaint"
import appFeedback from "./app-feedback"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    appData,
    appPetition,
    appShuhada,
    appNoc,
    appAdmin,
    appComplaint,
    appFeedback,
  },
  strict: process.env.DEV,
});
