function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}
import store from "../../store";
export default {
  namespaced: true,
  state: {
    navMenuItems: [],
  },
  getters: {
    getNavMenuItems: (state) => state.navMenuItems,
    getUser(state, getters, rootState, rootGetters) {
      return rootGetters["appData/getUser"];
    },
  },
  mutations: {
    setNavMenuItems(state, UserData) {
      state.navMenuItems = [];

      if (UserData.permissions.hasOwnProperty("petition_show")) {
        state.navMenuItems.push({
          title: "Petition",
          route: "Petitions",
          icon: "AirplayIcon",
        });
      }
      if (UserData.permissions.hasOwnProperty("petition_create")) {
        state.navMenuItems.push({
          title: "NOC",
          route: "Nocs",
          icon: "AirplayIcon",
        });
      }

      if (UserData.permissions.hasOwnProperty("show_complaint")) {
        state.navMenuItems.push({
          title: "Complaints",
          icon: "ClipboardIcon",
          route: "Complaint",
        });
      }
    },
  },
  actions: {
    setNavMenuItems({ commit, getters }) {
      const UserData = getters.getUser;
      commit("setNavMenuItems", UserData);
    },
  },
};
