import axiosIns from "@/libs/axios";
import axiosIns2 from "@/libs/axios2";
import { start } from "@popperjs/core";

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    user: null,
    tempUser: null,
    globalPolicies: null,
    systemTime: null,
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    getSystemTime: (state) => {
      return state.systemTime;
    },
    getGlobalPoliciesState: (state) => {
      return state.globalPolicies;
    },
    hasPermission: (state) => (name) => {
      if (Array.isArray(name)) {
        for (let i = 0; i < name.length; i++) {
          if (state.user.permissions.hasOwnProperty(name[i])) {
            return true;
          }
        }
      } else {
        return state.user.permissions.hasOwnProperty(name);
      }
      return false;
    },
    hasRole: (state) => (name) => {
      if (state.user) {
        if (state.user.role_data.code_name === name) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    isLoggedIn: (state) => state.isLoggedIn,
  },
  mutations: {
    login(state, data) {
      state.isLoggedIn = true;
      state.user = data.user;
      state.tempUser = data.user;
      localStorage.setItem("previous_user_role", data.user.role_data.code_name);
    },
    loginUser(state) {
      state.isLoggedIn = true;
      state.user = state.tempUser;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
    },
    setGlobalPoliciesState(state, policies) {
      state.globalPolicies = policies;
    },
    setSystemTime(state, policies) {
      state.systemTime = policies;
    },
    setLoggedInUser(state, user) {
      state.user = user
    },
  },
  actions: {
    async login({ commit }, payload) {
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.post("login", payload, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      if (res.status === 200) {
        commit("login", res.data);
      }
      return res;
    },
    async register({ commit }, payload) {
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.post("register", payload, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },
    async creatUser({ commit }, payload) {
      const res = await axiosIns.post("create-user", payload);
      return res;
    },
    async logout({ commit }) {
      const res = await axiosIns.post("logout");
      if (res.status === 204) {
        commit("logout");
      }
      return res;
    },
    logoutUnAuth({ commit }) {
      commit("logout");
    },
    loginUser({ commit }) {
      commit("loginUser");
    },
    async fetchLoggedInUser({ commit, getters }) {
      const res = await axiosIns.get(`afp-users/${getters.getUser.id}/`);
      if (res.status === 200) {
        var user = res.data;
        commit("setLoggedInUser", user);
        return res;
      } else {
        console.error("Error fetching user:", res.status);
        return {};
      }
    },
    async fetchSystemTime({ commit }) {
      const res = await axiosIns.get("system-time");
      if (res.status === 200) {
        var dateString = res.data;
        var systemDate = new Date(dateString);
        commit("setSystemTime", systemDate);
        return res;
      } else {
        console.error("Error fetching system time:", res.status);
        return {};
      }
    },
    async fetchGlobalPoliciesState({ commit }) {
      const res = await axiosIns.get("bms/global-policies-unpaginated");
      if (res.status === 200) {
        let policies = {};
        for (let i = 0; i < res.data.length; i++) {
          let policy = res.data[i];
          policies[policy.code] = policy.value;
        }
        commit("setGlobalPoliciesState", policies);
        return res;
      } else {
        console.error("Error fetching global policies:", res.status);
        return {};
      }
    },
    async createPetition({ commit }, payload) {
      const res = await axiosIns.post("petition/petition-create", payload);
      return res;
    },
    async createContactUs({ commit }, payload) {
      const res = await axiosIns.post("petition/contact-us/", payload);
      return res;
    },
    async createNoc({ commit }, payload) {
      const res = await axiosIns.post("noc/noc-details", payload);
      return res;
    },
    async updateProfile({ commit }, payload) {
      const pk = payload.pk;
      delete payload.pk;
      const res = await axiosIns.patch(`profile/${pk}`, payload);
      return res;
    },
    async createUserCall({ commit }, payload) {
      const res = await axiosIns.post("user-call", payload);
      return res;
    },
    async updateUser({ commit }, payload) {
      const pk = payload.pk;
      delete payload.pk;
      const res = await axiosIns.patch(`user/${pk}`, payload.payload);
      return res;
    },
    async deleteUser({ commit }, payload) {
      const pk = payload.pk;
      delete payload.pk;
      const res = await axiosIns.delete(`user/${pk}`);
      return res;
    },
    async permDeleteUser({ commit }, payload) {
      console.log(' here ');
      
      const pk = payload.pk;
      delete payload.pk;
      const res = await axiosIns.delete(`user-perm-delete/${pk}`);
      return res;
    },
    async changePassword({ commit }, payload) {
      const res = await axiosIns.post("changepassword", payload);
      return res;
    },
    async approveUser({ commit }, payload) {
      const res = await axiosIns.post("approveUser", payload);
      return res;
    },
    async rejectUser({ commit }, payload) {
      const res = await axiosIns.post("rejectUser", payload);
      return res;
    },
    async messageUser({ commit }, payload) {
      const res = await axiosIns.post("message-user", payload);
      return res;
    },
    async pendingUser({ commit }, payload) {
      const res = await axiosIns.post("pendingUser", payload);
      return res;
    },
    async updateImage({ commit }, payload) {
      const res = await axiosIns.post("image", payload);
      return res;
    },
    async importPetitionData({ commit }, payload) {
      const res = await axiosIns.post("petition/import-petition", payload);
      return res;
    },
    async importNocData({ commit }, payload) {
      const res = await axiosIns.post("noc/import-noc", payload);
      return res;
    },
    async update_status_to_close_send_sms({ commit }, payload) {
      const res = await axiosIns.post(
        "change-status-to-close-send-sms",
        payload
      );
      return res;
    },
    async addDocumentsToPetition({ commit }, data) {
      const id = data.id;
      const res = await axiosIns.post(
        `petition/petition-document/${id}`,
        data.formData
      );
      return res;
    },
    async addDocumentsToNoc({ commit }, data) {
      const id = data.id;
      const res = await axiosIns.post(`noc/noc-document/${id}`, data.formData);
      return res;
    },
    async partialUpdatePetition({ commit }, payload) {
      if (payload) {
        const id = payload.id;
        delete payload.id;
        const res = await axiosIns.patch(`petition/${id}`, payload);
        return res;
      }
    },
    async deletePetition({ commit }, payload) {
      if (payload) {
        const id = payload.id;
        const res = await axiosIns.delete(`petition/${id}`);
        return res;
      }
    },
    async deleteNoc({ commit }, payload) {
      if (payload) {
        const id = payload.id;
        const res = await axiosIns.delete(`noc/${id}`);
        return res;
      }
    },
    async receivePetition({ commit }, payload) {
      if (payload) {
        const id = payload.id;
        const res = await axiosIns.receive(`petition/${id}`);
        return res;
      }
    },
    async receiveNoc({ commit }, payload) {
      if (payload) {
        const id = payload.id;
        const res = await axiosIns.receive(`noc/${id}`);
        return res;
      }
    },
    async deletePetitionDocument({ commit }, payload) {
      if (payload.id) {
        const res = await axiosIns.delete(`petition/document/${payload.id}`);
        return res;
      }
    },
    async partialUpdateNoc({ commit }, payload) {
      if (payload) {
        const id = payload.id;
        delete payload.id;
        const res = await axiosIns.patch(`noc/${id}`, payload);
        return res;
      }
    },
    async deleteNoc({ commit }, payload) {
      if (payload) {
        const id = payload.id;
        const res = await axiosIns.delete(`noc/${id}`);
        return res;
      }
    },
    async deleteNocDocument({ commit }, payload) {
      if (payload.id) {
        const res = await axiosIns.delete(`noc/document/${payload.id}`);
        return res;
      }
    },
    async getPetitions({ commit }, payload) {
      let pgNumber = 1;
      if (
        payload.pageNumber !== null &&
        typeof payload.pageNumber !== "undefined"
      ) {
        pgNumber = payload.pageNumber;
      }
      let url = `petition/petition-create?page=${pgNumber}`;
      delete payload.pageNumber;
      Object.keys(payload).forEach(function (key) {
        url += `&${key}=${payload[key]}`;
      });
      const res = await axiosIns.get(url);
      return res;
    },
    async getNocs({ commit }, payload) {
      let pgNumber = 1;
      if (
        payload.pageNumber !== null &&
        typeof payload.pageNumber !== "undefined"
      ) {
        pgNumber = payload.pageNumber;
      }
      let url = `noc/noc-details?page=${pgNumber}`;
      delete payload.pageNumber;
      Object.keys(payload).forEach(function (key) {
        url += `&${key}=${payload[key]}`;
      });
      const res = await axiosIns.get(url);
      return res;
    },
    async getNocRoles({ commit }, payload) {
      let pgNumber = 1;
      if (
        payload.pageNumber !== null &&
        typeof payload.pageNumber !== "undefined"
      ) {
        pgNumber = payload.pageNumber;
      }
      let url = `roles?page=${pgNumber}`;
      if (payload.name !== null && typeof payload.name !== "undefined") {
        url += `&name=${payload.name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getPetitionRoles({ commit }, payload) {
      let pg_no = payload.page_number;
      let name = payload.name;
      if (!pg_no) {
        pg_no = 1;
      }
      let url = `roles?page=${pg_no}`;
      if (name !== null && typeof name !== "undefined") {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getShuhadaRoles({ commit }, payload) {
      let pgNumber = 1;
      if (
        payload.pageNumber !== null &&
        typeof payload.pageNumber !== "undefined"
      ) {
        pgNumber = payload.pageNumber;
      }
      let url = `roles?page=${pgNumber}`;
      if (payload.name !== null && typeof payload.name !== "undefined") {
        url += `&name=${payload.name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getPermissions({ commit }) {
      const res = await axiosIns.get("permissions");
      return res;
    },
    async updateRole({ commit }, payload) {
      const pk = payload.pk;
      delete payload.pk;
      const res = await axiosIns.put(`role/${pk}`, payload);
      return res;
    },
    async createRole({ commit }, payload) {
      delete payload.hospitalId;
      const res = await axiosIns.post(`roles`, payload);
      return res;
    },
    async deleteRole({ commit }, payload) {
      const pk = payload.pk;
      const res = await axiosIns.delete(`role/${pk}`);
      return res;
    },
    async getUnpaginatedPetitions({ commit }, payload) {
      let url = `petition/petition-unpaginated`;
      if (payload !== null && typeof payload !== "undefined") {
        let index = 1;
        Object.keys(payload).forEach(function (key) {
          if (index === 1) {
            url += `?${key}=${payload[key]}`;
          } else {
            url += `&${key}=${payload[key]}`;
          }
          index++;
        });
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async getUnpaginatedUnapprovedUsers({ commit }, payload) {
      let url = `signedup-user-unpaginated`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getExtensionApplications({ commit }, payload) {
      let url = `/noc/download-re-employment-noc`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getJobNocApplications({ commit }, payload) {
      let url = `/noc/download-job-noc`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getUnpaginatedUserCreateRoles({ commit }, payload) {
      let url = `user-create-role-unpaginated`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getDashboardData({ commit }, payload) {
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.post("dashboard", payload, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },
    async getDashboardReport({ commit }, payload) {
      const res = await axiosIns.post("dashboard-report-pdf", payload);
      return res;
    },
    async getAdminFilteredDashboardData({ commit }, payload) {
      const res = await axiosIns.post("dashboard-data", payload);
      return res;
    },
    async getPendingReasonData({ commit }, payload) {
      const res = await axiosIns.post("pending-reason-data", payload);
      return res;
    },
    async getUnpaginatedRoles({ commit }, payload) {
      let url = `role-unpaginated`;
      const res = await axiosIns.get(url);
      return res;
    },
    async createBookingUser({ commit }, payload) {
      const res = await axiosIns.post("create-booking-user", payload);
      return res;
    },
    async getAfpUsers(
      { commit },
      {
        pageNumber,
        name,
        cnic,
        user_type,
        role,
        mobile,
        role_code_name,
        bms_blocked,
      }
    ) {
      let url = `afp-users/?page=${pageNumber}`;
      if (name) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `name=${name}`;
      }
      if (cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `cnic=${cnic}`;
      }
      if (user_type) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `user_type=${user_type}`;
      }
      if (role) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `role=${role}`;
      }
      if (mobile) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mobile=${mobile}`;
      }
      if (role_code_name) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `role_code_name=${role_code_name}`;
      }
      if (bms_blocked) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `bms_blocked=${bms_blocked}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async updateAfpUser({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`afp-users/${pk}/`, payload);
      return res;
    },
    async getBookingUsers(
      { commit },
      { pageNumber, name, cnic, user_type, role, mobile, city }
    ) {
      let url = `booking-users/?page=${pageNumber}`;
      if (name) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `name=${name}`;
      }
      if (cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `cnic=${cnic}`;
      }
      if (user_type) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `user_type=${user_type}`;
      }
      if (role) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `role=${role}`;
      }
      if (mobile) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mobile=${mobile}`;
      }
      if (city) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `city=${city}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getBookingUnpaginatedRoles({ commit }, { only_booking_roles }) {
      let url = `role-unpaginated`;
      if (only_booking_roles) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `only_booking_roles=${only_booking_roles}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getPaginatedUsersWithFilters({ commit }, payload) {
      let pg_no = payload.page_number;
      let id = payload.id;
      let approved_at = payload.approved_at;
      let have_pending_reason = payload.have_pending_reason;
      let name = payload.name;
      let cnic = payload.cnic;
      let mobile = payload.mobile;
      let is_approved = payload.is_approved;
      let user_type = payload.user_type;
      let role = payload.role;
      let not_code_name = payload.not_code_name;
      let is_delete = payload.is_delete;
      let created_at_start = payload.created_at_start;
      let created_at_end = payload.created_at_end;
      if (!pg_no) {
        pg_no = 1;
      }
      let url = `users?page=${pg_no}`;
      if (id !== null && typeof id !== "undefined") {
        url += `&id=${id}`;
      }
      if (name !== null && typeof name !== "undefined") {
        url += `&name=${name}`;
      }
      if (
        have_pending_reason !== null &&
        typeof have_pending_reason !== "undefined"
      ) {
        url += `&have_pending_reason=${have_pending_reason}`;
      }
      if (cnic !== null && typeof cnic !== "undefined") {
        url += `&cnic=${cnic}`;
      }
      if (mobile !== null && typeof mobile !== "undefined") {
        url += `&mobile=${mobile}`;
      }
      if (is_approved !== null && typeof is_approved !== "undefined") {
        url += `&is_approved=${is_approved}`;
      }
      if (role !== null && typeof role !== "undefined") {
        url += `&role=${role}`;
      }
      if (approved_at !== null && typeof approved_at !== "undefined") {
        url += `&approved_at=${approved_at}`;
      }
      if (user_type !== null && typeof user_type !== "undefined") {
        url += `&user_type=${user_type}`;
      }
      if (not_code_name !== null && typeof not_code_name !== "undefined") {
        url += `&not_code_name=${not_code_name}`;
      }
      if (is_delete !== null && typeof is_delete !== "undefined") {
        url += `&is_delete=${is_delete}`;
      }
      if (
        created_at_start !== null &&
        typeof created_at_start !== "undefined"
      ) {
        url += `&created_at_start=${created_at_start}`;
      }
      if (created_at_end !== null && typeof created_at_end !== "undefined") {
        url += `&created_at_end=${created_at_end}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async downloadPetitionToFile({ commit }, payload) {
      let url = `petition/petition-download`;
      if (payload !== null && typeof payload !== "undefined") {
        let index = 1;
        Object.keys(payload).forEach(function (key) {
          if (index === 1) {
            url += `?${key}=${payload[key]}`;
          } else {
            url += `&${key}=${payload[key]}`;
          }
          index++;
        });
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async downloadNocToFile({ commit }, payload) {
      let url = `noc/noc-download`;
      if (payload !== null && typeof payload !== "undefined") {
        let index = 1;
        Object.keys(payload).forEach(function (key) {
          if (index === 1) {
            url += `?${key}=${payload[key]}`;
          } else {
            url += `&${key}=${payload[key]}`;
          }
          index++;
        });
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getCurrentUser({ commit }) {
      const res = await axiosIns.get("current");
      if (res.status === 200) {
        commit("login", res.data);
      }
    },
    async verifyOtp({ commit }, payload) {
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.post("otp", payload, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },
    async resendOtp({ commit }, payload) {
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.put("otp", payload, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },
    async sendOtp({ commit }, payload) {
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.put("forget-password", payload, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },
    async sendOtpMobile({ commit }, payload) {
      const res = await axiosIns.put("change-mobile", payload);
      return res;
    },
    async changeMobile({ commit }, payload) {
      const res = await axiosIns.post("change-mobile", payload);
      return res;
    },

    async resetPassword({ commit }, payload) {
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.post(`forget-password`, payload, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },
    async getNOCFields({ commit }, payload) {
      const res = await axiosIns.get(`noc/noc-form-fields`);
      return res;
    },
    async getUsersUnPaginated(
      { commit },
      {
        organization,
        circulation,
        permission,
        is_officer,
        no_mess_secretaries,
        no_mess_secretaries_except_current,
        no_clerks,
        no_clerks_except_current,
        exclude_user,
        only_booking_users,
      }
    ) {
      let url = `users-unpaginated/`;
      if (organization) {
        if (!url.includes("?")) {
          url += `?organization=${organization}`;
        } else {
          url += `&organization=${organization}`;
        }
      }
      if (circulation) {
        if (!url.includes("?")) {
          url += `?circulation=${circulation}`;
        } else {
          url += `&circulation=${circulation}`;
        }
      }
      if (permission) {
        if (!url.includes("?")) {
          url += `?permission=${permission}`;
        } else {
          url += `&permission=${permission}`;
        }
      }
      if (is_officer !== null) {
        if (!url.includes("?")) {
          url += `?is_officer=${is_officer}`;
        } else {
          url += `&is_officer=${is_officer}`;
        }
      }
      if (no_mess_secretaries) {
        if (!url.includes("?")) {
          url += `?no_mess_secretaries=${no_mess_secretaries}`;
        } else {
          url += `&no_mess_secretaries=${no_mess_secretaries}`;
        }
      }
      if (no_mess_secretaries_except_current) {
        if (!url.includes("?")) {
          url += `?no_mess_secretaries_except_current=${no_mess_secretaries_except_current}`;
        } else {
          url += `&no_mess_secretaries_except_current=${no_mess_secretaries_except_current}`;
        }
      }
      if (no_clerks) {
        if (!url.includes("?")) {
          url += `?no_clerks=${no_clerks}`;
        } else {
          url += `&no_clerks=${no_clerks}`;
        }
      }
      if (no_clerks_except_current) {
        if (!url.includes("?")) {
          url += `?no_clerks_except_current=${no_clerks_except_current}`;
        } else {
          url += `&no_clerks_except_current=${no_clerks_except_current}`;
        }
      }
      if (exclude_user) {
        if (!url.includes("?")) {
          url += `?exclude_user=${exclude_user}`;
        } else {
          url += `&exclude_user=${exclude_user}`;
        }
      }
      if (only_booking_users) {
        if (!url.includes("?")) {
          url += `?only_booking_users=${only_booking_users}`;
        } else {
          url += `&only_booking_users=${only_booking_users}`;
        }
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getBenefitTypesUnPaginated({ commit }) {
      const res = await axiosIns.get(`benefit-types-unpaginated/`);
      return res;
    },
    async getArmsSvcsUnPaginated({ commit }) {
      const res = await axiosIns.get(`arms-svc-unpaginated/`);
      return res;
    },
    async getClearStatussUnPaginated({ commit }) {
      const res = await axiosIns.get(`clear-status-unpaginated/`);
      return res;
    },
    async getDepartmentsUnPaginated({ commit }) {
      const res = await axiosIns.get(`departments-unpaginated/`);
      return res;
    },
    async getDistrictsUnPaginated({ commit }) {
      const res = await axiosIns.get(`district-unpaginated/`);
      return res;
    },

    // Complaint Calls

    async getOrganizations({ commit }, { pageNumber, name, parent }) {
      let url = `complaint/organizations/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async getOrganization({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/organizations/${pk}/`);
      return res;
    },

    async getOrganizationsUnpaginated({ commit }) {
      const res = await axiosIns.get(`complaint/organizations-unpaginated`);
      return res;
    },

    // async getComplainants({ commit }) {
    //   let url = `complainant/`;
    //   const res = await axiosIns.get(url);
    //   return res;
    // },

    async getComplainantsUnpaginated({ commit }) {
      const res = await axiosIns.get(`complaint/complainants-unpaginated`);
      return res;
    },

    async getComplainantTypes({ commit }, { pageNumber, name }) {
      let url = `complaint/complainant-type/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getComplainantType({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/complainant-type/${pk}/`);
      return res;
    },
    async createComplainantType({ commit }, payload) {
      const res = await axiosIns.post("complaint/complainant-type/", payload);
      return res;
    },
    async updateComplainantType({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(
        `complaint/complainant-type/${pk}/`,
        payload
      );
      return res;
    },
    async deleteComplainantType({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/complainant-type/${pk}/`);
      return res;
    },

    async getCities({ commit }, { pageNumber, name }) {
      let url = `complaint/city/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getCity({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/city/${pk}/`);
      return res;
    },
    async createCity({ commit }, payload) {
      const res = await axiosIns.post("complaint/city/", payload);
      return res;
    },
    async updateCity({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`complaint/city/${pk}/`, payload);
      return res;
    },
    async deleteCity({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/city/${pk}/`);
      return res;
    },

    async getComplaints(
      { commit },
      {
        pageNumber,
        status,
        not_status,
        category,
        subcategory,
        created_at,
        army_prefix,
        army_no,
        cnic,
        rank_type,
        approved_by,
        created_by,
        currently_marked,
        organization,
        exceed_by_10_days,
        exceed_by_48_hrs,
        delayed_by_48_hrs,
        created_by_organization,
        case_no,
        old_case_no,
        first_name,
        last_name,
        draft,
        pend_marked,
        is_delete,
        from_date,
        to_date,
        marked_by_organization,
        not_organization,
        last_30_days,
        my_organization,
        date_range,
        marked_to_user,
        marked_from_cnic,
        marked_to_organization,
        user_complaints,
        complaint_type,
      }
    ) {
      const headers = [
        "status",
        "not_status",
        "category",
        "subcategory",
        "created_at",
        "army_prefix",
        "army_no",
        "cnic",
        "rank_type",
        "approved_by",
        "created_by",
        "currently_marked",
        "organization",
        "exceed_by_10_days",
        "exceed_by_48_hrs",
        "delayed_by_48_hrs",
        "created_by_organization",
        "case_no",
        "old_case_no",
        "first_name",
        "last_name",
        "draft",
        "pend_marked",
        "is_delete",
        "from_date",
        "to_date",
        "marked_by_organization",
        "not_organization",
        "last_30_days",
        "my_organization",
        "date_range",
        "marked_to_user",
        "marked_from_cnic",
        "marked_to_organization",
        "user_complaints",
        "complaint_type",
      ];

      let url = `complaint/complaint/?page=${pageNumber}`;

      headers.forEach((header) => {
        const value = eval(header); // Using eval() to get the value of the header
        if (value !== undefined && value !== null) {
          url += `&${header}=${value}`;
        }
      });

      const res = await axiosIns.get(url);
      return res;
    },
    async deleteComplaint({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/complaint/${pk}/`);
      return res;
    },
    async getComplaintFormFields({ commit }) {
      const res = await axiosIns.get(`complaint/complaint-form-fields`);
      return res;
    },

    async getComplaint({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/complaint/${pk}/`);
      return res;
    },
    async createComplaint({ commit }, payload) {
      const res = await axiosIns.post("complaint/complaint/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res;
    },
    async updateComplaint({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`complaint/complaint/${pk}/`, payload);
      return res;
    },
    async deleteComplaint({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/complaint/${pk}/`);
      return res;
    },

    async getComplaintCategories(
      { commit },
      { pageNumber, name, parent_name, is_active }
    ) {
      let url = `complaint/complaint-category/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      if (parent_name) {
        url += `&parent_name=${parent_name}`;
      }
      if (is_active !== undefined) {
        url += `&is_active=${is_active}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getComplaintCategoriesUnpaginated(
      { commit },
      { current_category_id, no_parent }
    ) {
      const res = await axiosIns.get(
        `complaint/complaint-categories-unpaginated`,
        {
          params: {
            current_category_id: current_category_id,
            no_parent: no_parent,
          },
        }
      );
      return res;
    },
    async getComplaintCategory({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/complaint-category/${pk}/`);
      return res;
    },
    async createComplaintCategory({ commit }, payload) {
      const res = await axiosIns.post("complaint/complaint-category/", payload);
      return res;
    },
    async updateComplaintCategory({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(
        `complaint/complaint-category/${pk}/`,
        payload
      );
      return res;
    },
    async deleteComplaintCategory({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/complaint-category/${pk}/`);
      return res;
    },

    async createPrefix({ commit }, payload) {
      const res = await axiosIns.post("complaint/prefix/", payload);
      return res;
    },
    async getPrefixes({ commit }, { pageNumber, name }) {
      let url = `complaint/prefix/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getPrefix({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/prefix/${pk}/`);
      return res;
    },
    async deletePrefix({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/prefix/${pk}/`);
      return res;
    },
    async updatePrefix({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`complaint/prefix/${pk}/`, payload);
      return res;
    },

    async getComplaintRequestSources({ commit }, { pageNumber, name }) {
      let url = `complaint/complaint-request-source/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getComplaintRequestSource({ commit }, { pk }) {
      const res = await axiosIns.get(
        `complaint/complaint-request-source/${pk}/`
      );
      return res;
    },
    async createComplaintRequestSource({ commit }, payload) {
      const res = await axiosIns.post(
        "complaint/complaint-request-source/",
        payload
      );
      return res;
    },
    async updateComplaintRequestSource({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(
        `complaint/complaint-request-source/${pk}/`,
        payload
      );
      return res;
    },
    async deleteComplaintRequestSource({ commit }, { pk }) {
      const res = await axiosIns.delete(
        `complaint/complaint-request-source/${pk}/`
      );
      return res;
    },

    async getComplaintTypes({ commit }, { pageNumber, name }) {
      let url = `complaint/complaint-type/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getComplaintType({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/complaint-type/${pk}/`);
      return res;
    },
    async createComplaintType({ commit }, payload) {
      const res = await axiosIns.post("complaint/complaint-type/", payload);
      return res;
    },
    async updateComplaintType({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(
        `complaint/complaint-type/${pk}/`,
        payload
      );
      return res;
    },
    async deleteComplaintType({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/complaint-type/${pk}/`);
      return res;
    },

    async getMaritalStatuses({ commit }, { pageNumber, name }) {
      let url = `complaint/marital-status/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getMaritalStatus({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/marital-status/${pk}/`);
      return res;
    },
    async createMaritalStatus({ commit }, payload) {
      const res = await axiosIns.post("complaint/marital-status/", payload);
      return res;
    },
    async updateMaritalStatus({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(
        `complaint/marital-status/${pk}/`,
        payload
      );
      return res;
    },
    async deleteMaritalStatus({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/marital-status/${pk}/`);
      return res;
    },

    async getRankTypes({ commit }, { pageNumber, name }) {
      let url = `complaint/rank-type/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getRankType({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/rank-type/${pk}/`);
      return res;
    },
    async createRankType({ commit }, payload) {
      const res = await axiosIns.post("complaint/rank-type/", payload);
      return res;
    },
    async updateRankType({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`complaint/rank-type/${pk}/`, payload);
      return res;
    },
    async deleteRankType({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/rank-type/${pk}/`);
      return res;
    },

    async getRegiments({ commit }, { pageNumber, name }) {
      let url = `complaint/regiment/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getRegiment({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/regiment/${pk}/`);
      return res;
    },
    async createRegiment({ commit }, payload) {
      const res = await axiosIns.post("complaint/regiment/", payload);
      return res;
    },
    async updateRegiment({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`complaint/regiment/${pk}/`, payload);
      return res;
    },
    async deleteRegiment({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/regiment/${pk}/`);
      return res;
    },

    async getRelationTypes({ commit }, { pageNumber, name }) {
      let url = `complaint/relation-type/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getRelationType({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/relation-type/${pk}/`);
      return res;
    },
    async createRelationType({ commit }, payload) {
      const res = await axiosIns.post("complaint/relation-type/", payload);
      return res;
    },
    async updateRelationType({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(
        `complaint/relation-type/${pk}/`,
        payload
      );
      return res;
    },
    async deleteRelationType({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/relation-type/${pk}/`);
      return res;
    },

    async getServingStatuses({ commit }, { pageNumber, name }) {
      let url = `complaint/serving-status/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getServingStatus({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/serving-status/${pk}/`);
      return res;
    },
    async createServingStatus({ commit }, payload) {
      const res = await axiosIns.post("complaint/serving-status/", payload);
      return res;
    },
    async updateServingStatus({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(
        `complaint/serving-status/${pk}/`,
        payload
      );
      return res;
    },
    async deleteServingStatus({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/serving-status/${pk}/`);
      return res;
    },

    async getComplaintCallerTypes({ commit }, { pageNumber, name }) {
      let url = `complaint/complaint-caller-type/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (parent) {
      //   url += `&parent=${parent}`;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getComplaintCallerType({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/complaint-caller-type/${pk}/`);
      return res;
    },
    async createComplaintCallerType({ commit }, payload) {
      const res = await axiosIns.post(
        "complaint/complaint-caller-type/",
        payload
      );
      return res;
    },
    async updateComplaintCallerType({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(
        `complaint/complaint-caller-type/${pk}/`,
        payload
      );
      return res;
    },
    async deleteComplaintCallerType({ commit }, { pk }) {
      const res = await axiosIns.delete(
        `complaint/complaint-caller-type/${pk}/`
      );
      return res;
    },

    async createDocument({ commit }, payload) {
      const res = await axiosIns.post("complaint/upload-document", payload);
      return res;
    },
    async getDocuments({ commit }, { pageNumber, name, created_by }) {
      let url = `complaint/documents/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      if (created_by) {
        url += `&created_by=${created_by}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async getStatisticsData({ commit }) {
      const res = await axiosIns.get(`complaint/statistics-data`);
      return res;
    },
    async getOverallComplaintTypeStatusPlot({ commit }) {
      const res = await axiosIns.get(
        `complaint/overall-complaint-by-type-status-plot`
      );
      return res;
    },
    async getNoOfComplaintPlot({ commit }, { start_date, end_date }) {
      const res = await axiosIns.get(`complaint/no-of-complaint-plot`, {
        params: { start_date: start_date, end_date: end_date },
      });
      return res;
    },
    async getOverallComplaintCatStatusPlot({ commit }) {
      const res = await axiosIns.get(
        `complaint/overall-complaint-by-cat-status-plot`
      );
      return res;
    },
    async getOverallComplaintCatPlot({ commit }) {
      const res = await axiosIns.get(`complaint/overall-complaint-by-cat-plot`);
      return res;
    },
    async getDirComplaintByDtePlot({ commit }, { start_date, end_date }) {
      const res = await axiosIns.get(`complaint/dir-complaint-by-dte-plot`, {
        params: { start_date: start_date, end_date: end_date },
      });
      return res;
    },
    async getComplaintCatPlot({ commit }, { isToday }) {
      const res = await axiosIns.get(`complaint/complaint-by-cat`, {
        params: { isToday: isToday },
      });
      return res;
    },
    async getComplaintTypePlot({ commit }, { isToday }) {
      const res = await axiosIns.get(`complaint/complaint-by-type`, {
        params: { isToday: isToday },
      });
      return res;
    },

    async createComplaintLink({ commit }, payload) {
      const res = await axiosIns.post("complaint/complaint-links/", payload);
      return res;
    },
    async getComplaintLinks(
      { commit },
      { pageNumber, created_by_organization, approved_by, created_by }
    ) {
      let url = `complaint/complaint-links/?page=${pageNumber}`;
      if (created_by_organization) {
        url += `&created_by_organization=${created_by_organization}`;
      }
      if (approved_by) {
        url += `&approved_by=${approved_by}`;
      }
      if (created_by) {
        url += `&created_by=${created_by}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getComplaintLink({ commit }, { pk }) {
      const res = await axiosIns.get(`complaint/complaint-links/${pk}/`);
      return res;
    },
    async deleteComplaintLink({ commit }, { pk }) {
      const res = await axiosIns.delete(`complaint/complaint-links/${pk}/`);
      return res;
    },
    async updateComplaintLink({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(
        `complaint/complaint-links/${pk}/`,
        payload
      );
      return res;
    },
    async downloadComplaintToFile({ commit }) {
      const res = await axiosIns.get("complaint/download-complaints");
      return res;
    },

    // feedback

    async hospitals({ commit }) {
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.get("feedback/hospitals", {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },

    async getFeedbackData({ commit }, feedbackid) {
      let url = `feedback/feedbacks/${feedbackid}`;
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.get(url, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },

    async createFeedback({ commit }, payload) {
      const res = await axiosIns.post("feedback/feedbacks-create", payload);
      return res;
    },

    async submitFeedback({ commit }, payload) {
      let id = payload.feedbackid;
      delete payload.id;
      let url = `feedback/feedbacks/${id}`;

      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.post(url, payload, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },

    async getFeedbacks({ commit }, payload) {
      let pgNumber = 1;
      if (
        payload.pageNumber !== null &&
        typeof payload.pageNumber !== "undefined"
      ) {
        pgNumber = payload.pageNumber;
      }
      let url = `feedback/feedback-list?page=${pgNumber}`;
      delete payload.pageNumber;
      Object.keys(payload).forEach(function (key) {
        url += `&${key}=${payload[key]}`;
      });
      let apiKey = process.env.VUE_APP_API_KEY;
      const res = await axiosIns.get(url, {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
        },
      });
      return res;
    },
    async fetchFeedbackSummary({ commit }, payload) {
      let url = `feedback/feedback-rating?`;
      Object.keys(payload).forEach(function (key) {
        url += `&${key}=${payload[key]}`;
      });
      const res = await axiosIns.get(url);
      return res;
    },

    //BOOKING

    async getAvailableRooms(
      { commit },
      { date_from, date_to, rooms, city, booking_for, mess_id }
    ) {
      let url = `bms/available-rooms`;
      if (date_from) {
        url += `?date_from=${date_from}`;
      }
      if (date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_to=${date_to}`;
      }
      if (city) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `city=${city}`;
      }
      if (rooms) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `rooms=${rooms}`;
      }
      if (booking_for) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `booking_for=${booking_for.id}`;
      }
      if (mess_id) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess_id=${mess_id}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },

    async getBookings(
      { commit },
      {
        pageNumber,
        customer,
        customer_name,
        customer_cnic,
        room_name,
        mess,
        mess_name,
        // hotel_name,
        city_name,
        managing_hq_name,
        status,
        voucher_no,
        payment_status,
        created_at_from,
        created_at_to,
        start_date_time_from,
        start_date_time_to,
        end_date_time_from,
        end_date_time_to,
        start_date,
        end_date,
        all_paid,
        no_cancelled_unpaid,
        order_by,
        sort_order,
        checked_in,
        checked_out,
        checkout_status,
        created_by,
        booking_flow
      }
    ) {
      let url = `bms/bookings/?page=${pageNumber}`;
      if (customer_name) {
        url += `&customer_name=${customer_name}`;
      }
      if (customer_cnic) {
        url += `&customer_cnic=${customer_cnic}`;
      }
      if (customer) {
        url += `&customer=${customer}`;
      }
      if (room_name) {
        url += `&room_name=${room_name}`;
      }
      if (mess) {
        url += `&mess=${mess}`;
      }
      if (mess_name) {
        url += `&mess_name=${mess_name}`;
      }
      // if (hotel_name) {
      // url += `&hotel_name=${hotel_name}`;
      // }
      if (city_name) {
        url += `&city_name=${city_name}`;
      }
      if (managing_hq_name) {
        url += `&managing_hq_name=${managing_hq_name}`;
      }
      if (status) {
        url += `&status=${status}`;
      }
      if (voucher_no) {
        url += `&voucher_no=${voucher_no}`;
      }
      if (payment_status) {
        url += `&payment_status=${payment_status}`;
      }
      if (created_at_from) {
        url += `&created_at_from=${created_at_from}`;
      }
      if (created_at_to) {
        url += `&created_at_to=${created_at_to}`;
      }
      if (start_date_time_from) {
        url += `&start_date_time_from=${start_date_time_from}`;
      }
      if (start_date_time_to) {
        url += `&start_date_time_to=${start_date_time_to}`;
      }
      if (end_date_time_from) {
        url += `&end_date_time_from=${end_date_time_from}`;
      }
      if (end_date_time_to) {
        url += `&end_date_time_to=${end_date_time_to}`;
      }
      if (start_date) {
        url += `&start_date=${start_date}`;
      }
      if (end_date) {
        url += `&end_date=${end_date}`;
      }
      if (all_paid) {
        url += `&all_paid=${all_paid}`;
      }
      if (no_cancelled_unpaid) {
        url += `&no_cancelled_unpaid=${no_cancelled_unpaid}`;
      }
      // if (is_delete) {
      //   url += `&is_delete=${is_delete}`;
      // }
      // else {
      //   url += `&is_delete=` + false;
      // }
      if (checked_in !== null && typeof checked_in !== "undefined") {
        url += `&checked_in=${checked_in}`;
      }
      if (checked_out !== null && typeof checked_out !== "undefined") {
        url += `&checked_out=${checked_out}`;
      }
      if (checkout_status) {
        url += `&checkout_status=${checkout_status}`;
      }
      if (created_by) {
        url += `&created_by=${created_by}`;
      }
      if (booking_flow) {
        url += `&booking_flow=${booking_flow}`;
      }
      if (order_by) {
        url += `&ordering=${sort_order}${order_by}`;
      } else {
        url += `&ordering=-id`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getBooking({ commit }, id) {
      let url = `bms/bookings/${id}`;
      const res = await axiosIns.get(url);
      return res;
    },

    async createBooking({ commit }, payload) {
      const res = await axiosIns.post("bms/bookings/", payload);
      return res;
    },

    async updateBooking({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/bookings/${pk}/`, payload);
      return res;
    },

    async deleteBooking({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/bookings/${pk}/`);
      return res;
    },

    async getBillInquiry({ commit }, payload) {
      const res = await axiosIns.post("bms/bill-inquiry", payload);
      return res;
    },

    async makeBillPayment({ commit }, payload) {
      const res = await axiosIns.post("bms/bill-payment", payload);
      return res;
    },

    // async getHotels({ commit }, { pageNumber, name }) {
    // let url = `bms/hotels/?page=${pageNumber}`;
    // if (name) {
    // url += `&name=${name}`;
    // }
    // if (is_delete) {
    //   url += `&is_delete=${is_delete}`;
    // }
    // else {
    //   url += `&is_delete=` + false;
    // }
    // const res = await axiosIns.get(url);
    // return res;
    // },

    // async getHotelsUnpaginated() {
    // const res = await axiosIns.get("bms/hotels-unpaginated");
    // return res;
    // },

    async getRooms(
      { commit },
      {
        pageNumber,
        name,
        mess,
        mess_name,
        city,
        city_name,
        room_type,
        room_type_name,
        is_unavailable,
      }
    ) {
      let url = `bms/rooms/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      if (mess) {
        url += `&mess=${mess}`;
      }
      if (mess_name) {
        url += `&mess_name=${mess_name}`;
      }
      if (city) {
        url += `&city=${city}`;
      }
      if (city_name) {
        url += `&city_name=${city_name}`;
      }
      if (room_type) {
        url += `&room_type=${room_type}`;
      }
      if (room_type_name) {
        url += `&room_type_name=${room_type_name}`;
      }
      // if (is_delete) {
      //   url += `&is_delete=${is_delete}`;
      // }
      // else {
      //   url += `&is_delete=` + false;
      // }
      if (is_unavailable !== null && typeof is_unavailable !== "undefined") {
        url += `&is_unavailable=${is_unavailable}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async createRoom({ commit }, payload) {
      const res = await axiosIns.post("bms/rooms/", payload);
      return res;
    },

    async updateRoom({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/rooms/${pk}/`, payload);
      return res;
    },

    async deleteRoom({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/rooms/${pk}/`);
      return res;
    },

    async getRoomsUnpaginated(
      { commit },
      {
        mess,
        available_rooms,
        available_rooms_including_current,
        no_agm_rooms,
        only_agm_rooms,
      }
    ) {
      let url = `bms/rooms-unpaginated`;
      if (available_rooms) {
        url += `/?available_rooms=${available_rooms}`;
      }
      if (available_rooms_including_current) {
        url += `/?available_rooms_including_current=${available_rooms_including_current}`;
      }
      if (mess) {
        if (!url.includes("?")) {
          url += `/?mess=${mess}`;
        } else {
          url += `&mess=${mess}`;
        }
      }
      if (no_agm_rooms) {
        if (!url.includes("?")) {
          url += `/?no_agm_rooms=${no_agm_rooms}`;
        } else {
          url += `&no_agm_rooms=${no_agm_rooms}`;
        }
      }
      if (only_agm_rooms) {
        if (!url.includes("?")) {
          url += `/?only_agm_rooms=${only_agm_rooms}`;
        } else {
          url += `&only_agm_rooms=${only_agm_rooms}`;
        }
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async getManagingHqs({ commit }, { pageNumber, name }) {
      let url = `bms/managing-hqs/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (is_delete) {
      //   url += `&is_delete=${is_delete}`;
      // }
      // else {
      //   url += `&is_delete=` + false;
      // }
      const res = await axiosIns.get(url);
      return res;
    },

    async getManagingHqsUnpaginated() {
      const res = await axiosIns.get("bms/managing-hqs-unpaginated");
      return res;
    },

    async createManagingHq({ commit }, payload) {
      const res = await axiosIns.post("bms/managing-hqs/", payload);
      return res;
    },

    async updateManagingHq({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/managing-hqs/${pk}/`, payload);
      return res;
    },

    async deleteManagingHq({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/managing-hqs/${pk}/`);
      return res;
    },

    async getGlobalPolicies({ commit }, { pageNumber, name }) {
      let url = `bms/global-policies/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (is_delete) {
      //   url += `&is_delete=${is_delete}`;
      // }
      // else {
      //   url += `&is_delete=` + false;
      // }
      const res = await axiosIns.get(url);
      return res;
    },
    async getGlobalPoliciesUnpaginated() {
      const res = await axiosIns.get("bms/global-policy-unpaginated");
      return res;
    },

    async createGlobalPolicy({ commit }, payload) {
      const res = await axiosIns.post("bms/global-policies/", payload);
      return res;
    },
    async updateGlobalPolicy({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/global-policies/${pk}/`, payload);
      return res;
    },

    async deleteGlobalPolicy({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/global-policies/${pk}/`);
      return res;
    },

    async getAnnouncements({ commit }, { pageNumber, name }) {
      let url = `announcements/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getAnnouncementsUnpaginated() {
      const res = await axiosIns.get("announcements-unpaginated");
      return res;
    },

    async createAnnouncement({ commit }, payload) {
      const res = await axiosIns.post("announcements/", payload);
      return res;
    },
    async updateAnnouncement({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`announcements/${pk}/`, payload);
      return res;
    },

    async deleteAnnouncement({ commit }, { pk }) {
      const res = await axiosIns.delete(`announcements/${pk}/`);
      return res;
    },

    async getRoomTypes({ commit }, { pageNumber, name }) {
      let url = `bms/room-types/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (is_delete) {
      //   url += `&is_delete=${is_delete}`;
      // }
      // else {
      //   url += `&is_delete=` + false;
      // }
      const res = await axiosIns.get(url);
      return res;
    },

    async createRoomType({ commit }, payload) {
      const res = await axiosIns.post("bms/room-types/", payload);
      return res;
    },

    async updateRoomType({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/room-types/${pk}/`, payload);
      return res;
    },

    async deleteRoomType({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/room-types/${pk}/`);
      return res;
    },

    async getRoomTypesUnpaginated() {
      const res = await axiosIns.get("bms/room-types-unpaginated");
      return res;
    },

    async getRoomFormData() {
      const res = await axiosIns.get("bms/room-form-data");
      return res;
    },

    //hotel_name removed from getMesses
    async getMesses(
      { commit },
      { pageNumber, name, city_name, managing_hq_name }
    ) {
      let url = `bms/messes/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      // if (hotel_name) {
      // url += `&hotel_name=${hotel_name}`;
      // }
      if (city_name) {
        url += `&city_name=${city_name}`;
      }
      if (managing_hq_name) {
        url += `&managing_hq_name=${managing_hq_name}`;
      }
      // if (is_delete) {
      //   url += `&is_delete=${is_delete}`;
      // }
      // else {
      //   url += `&is_delete=` + false;
      // }
      const res = await axiosIns.get(url);
      return res;
    },

    async createMess({ commit }, payload) {
      const res = await axiosIns.post("bms/messes/", payload);
      return res;
    },

    async updateMess({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/messes/${pk}/`, payload);
      return res;
    },

    async deleteMess({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/messes/${pk}/`);
      return res;
    },

    async getBanksUnpaginated() {
      const res = await axiosIns.get("bms/banks-unpaginated");
      return res;
    },

    async getMessesUnpaginated(
      { commit },
      { city, not_assigned, not_assigned_except_current, available_messes }
    ) {
      let url = `bms/messes-unpaginated`;
      if (not_assigned) {
        url += `/?not_assigned=${not_assigned}`;
      }
      if (not_assigned_except_current) {
        url += `/?not_assigned_except_current=${not_assigned_except_current}`;
      }
      if (city) {
        if (!url.includes("?")) {
          url += `/?city=${city}`;
        } else {
          url += `&city=${city}`;
        }
      }
      if (available_messes) {
        if (!url.includes("?")) {
          url += `/?available_messes=${available_messes}`;
        } else {
          url += `&available_messes=${available_messes}`;
        }
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async getMess({ commit }, id) {
      let url = `bms/messes/${id}`;

      const res = await axiosIns.get(url);

      return res;
    },
    async getRoomDetails({ commit }, id) {
      let url = `/bms/mess-rooms-report?mess_id=${id}`;
      const res = await axiosIns.get(url);
      return res;
    },

    // async getCities(){
    //   const res = await axiosIns.get('bms/cities')
    //   return res
    // },

    async getMessSecretaries(
      { commit },
      { pageNumber, mess_secretary_name, mess_name, city_name }
    ) {
      let url = `bms/mess-secretaries/?page=${pageNumber}`;
      if (mess_secretary_name) {
        url += `&mess_secretary_name=${mess_secretary_name}`;
      }
      if (mess_name) {
        url += `&mess_name=${mess_name}`;
      }
      if (city_name) {
        url += `&city_name=${city_name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async createMessSecretary({ commit }, payload) {
      const res = await axiosIns.post("bms/mess-secretaries/", payload);
      return res;
    },

    async updateMessSecretary({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/mess-secretaries/${pk}/`, payload);
      return res;
    },

    async deleteMessSecretary({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/mess-secretaries/${pk}/`);
      return res;
    },

    async getMessSecretariesUnpaginated() {
      const res = await axiosIns.get("bms/mess-secretaries-unpaginated");
      return res;
    },

    async getClerks({ commit }, { pageNumber, clerk_name, mess, mess_name, city_name }) {
      let url = `bms/clerks/?page=${pageNumber}`;
      if (clerk_name) {
        url += `&clerk_name=${clerk_name}`;
      }
      if (mess) {
        url += `&mess=${mess}`;
      }
      if (mess_name) {
        url += `&mess_name=${mess_name}`;
      }
      if (city_name) {
        url += `&city_name=${city_name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async createClerk({ commit }, payload) {
      const res = await axiosIns.post("bms/clerks/", payload);
      return res;
    },
    async updateClerk({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/clerks/${pk}/`, payload);
      return res;
    },
    async deleteClerk({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/clerks/${pk}/`);
      return res;
    },
    async getClerksUnpaginated() {
      const res = await axiosIns.get("bms/clerks-unpaginated");
      return res;
    },

    async getFacilities({ commit }, { pageNumber, name }) {
      let url = `bms/facilities/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async createFacility({ commit }, payload) {
      const res = await axiosIns.post("bms/facilities/", payload);
      return res;
    },

    async updateFacility({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/facilities/${pk}/`, payload);
      return res;
    },

    async deleteFacility({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/facilities/${pk}/`);
      return res;
    },

    async getFacilitiesUnpaginated() {
      const res = await axiosIns.get("bms/facilities-unpaginated");
      return res;
    },

    async getFacility({ commit }, id) {
      let url = `bms/facilities/${id}`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getBookingCities({ commit }, { pageNumber, name }) {
      let url = `bms/cities/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getBookingCity({ commit }, { pk }) {
      const res = await axiosIns.get(`bms/cities/${pk}/`);
      return res;
    },
    async createBookingCity({ commit }, payload) {
      const res = await axiosIns.post("bms/cities/", payload);
      return res;
    },
    async updateBookingCity({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/cities/${pk}/`, payload);
      return res;
    },
    async deleteBookingCity({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/cities/${pk}/`);
      return res;
    },
    async getBookingCitiesUnpaginated({ commit }, { mess_only }) {
      let url = `bms/cities-unpaginated`;
      if (mess_only) {
        url += `/?mess_only=${mess_only}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async createBankAccount({ commit }, payload) {
      const res = await axiosIns.post("bms/bank-accounts/", payload);
      return res;
    },

    // PS Dte NOC
    async uploadData({ commit }, payload) {
      const res = await axiosIns.post(`noc/cnic-list`, payload);
      return res;
    },

    // Upload CNIC list To Change User Type to Ret from serving
    async uploadCnicList({ commit }, payload) {
      const res = await axiosIns.post(
        `upload_user_cnic_to_change_user_type`,
        payload
      );
      return res;
    },

    // ENOC

    async geteNocs({ commit }, payload) {
      let pgNumber = 1;
      if (
        payload.pageNumber !== null &&
        typeof payload.pageNumber !== "undefined"
      ) {
        pgNumber = payload.pageNumber;
      }
      let url = `noc/enoc?page=${pgNumber}`;
      delete payload.pageNumber;
      Object.keys(payload).forEach(function (key) {
        url += `&${key}=${payload[key]}`;
      });
      const res = await axiosIns.get(url);
      return res;
    },
    async getpdfeNocs({ commit }, id) {
      let url = `noc/download-enoc/${id}`;
      const res = await axiosIns.get(url);
      return res;
    },

    async partialUpdateENoc({ commit }, payload) {
      if (payload) {
        const id = payload.id;
        delete payload.id;
        const res = await axiosIns.patch(`noc/enoc/${id}`, payload);
        return res;
      }
    },

    async createENoc({ commit }, payload) {
      const res = await axiosIns.post("noc/enoc", payload);
      return res;
    },

    async deleteENoc({ commit }, payload) {
      if (payload) {
        const id = payload.id;
        const res = await axiosIns.delete(`noc/enoc/${id}`);
        return res;
      }
    },
    async fetchCnicLogs({ commit }) {
      const res = await axiosIns.get(`noc/cnic-logs`);
      return res;
    },

    // Re-Employment and Job NOCs
    async submitExtensionRequest({ commit }, payload) {
      const res = await axiosIns.post("noc/re-employment-noc/", payload);
      return res;
    },

    async getEmployments({ commit }, payload) {
      let pgNumber = 1;
      if (
        payload.pageNumber !== null &&
        typeof payload.pageNumber !== "undefined"
      ) {
        pgNumber = payload.pageNumber;
      }
      let url = `noc/re-employment-noc?page=${pgNumber}`;
      delete payload.pageNumber;
      Object.keys(payload).forEach(function (key) {
        url += `&${key}=${payload[key]}`;
      });
      const res = await axiosIns.get(url);
      return res;
    },

    async submitNocRequest({ commit }, payload) {
      const res = await axiosIns.post("noc/job-noc/", payload);
      return res;
    },

    async getJobNocs({ commit }, payload) {
      let pgNumber = 1;
      if (
        payload.pageNumber !== null &&
        typeof payload.pageNumber !== "undefined"
      ) {
        pgNumber = payload.pageNumber;
      }
      let url = `noc/job-noc?page=${pgNumber}`;
      delete payload.pageNumber;
      Object.keys(payload).forEach(function (key) {
        url += `&${key}=${payload[key]}`;
      });
      const res = await axiosIns.get(url);
      return res;
    },

    async partialUpdateJobNoc({ commit }, payload) {
      if (payload) {
        const res = await axiosIns.patch(`noc/job-noc/${payload.id}/`, payload);
        return res;
      }
    },
    async getpdfJobNoc({ commit }, id) {
      let url = `noc/download-job-noc-pdf/${id}`;
      const res = await axiosIns.get(url);
      return res;
    },

    // Upload function for Users Registered on DCR

    async uploadRegisteredUsers({ commit }, payload) {
      const res = await axiosIns.post(`upload_user_registerd_on_dcr`, payload);
      return res;
    },

    // async CancelBooking({ commit }, { payload, pk }) {
    //   const res = await axiosIns.post(`/bms/cancel-booking`, payload);
    //   return res;
    // },

    async CancelBooking({ commit }, payload) {
      const res = await axiosIns.post("bms/cancel-booking", payload);
      return res;
    },

    async sendEmail({ commit }, payload) {
      const res = await axiosIns.post("send-user-data-email/", payload);
      return res;
    },

    async getFinancialCheckoutReport(
      { commit },
      {
        voucher_no,
        customer_cnic,
        guest_cnic,
        date_from,
        date_to,
        mess,
        city,
        managing_hq,
        resolved,
        payment_date_from,
        payment_date_to,
        mess_payment_date_from,
        mess_payment_date_to,
        check_in_date_from,
        check_in_date_to,
        check_out_date_from,
        check_out_date_to,
      }
    ) {
      let url = `bms/financial-checkout-report`;
      if (voucher_no) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `voucher_no=${voucher_no}`;
      }
      if (customer_cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `customer_cnic=${customer_cnic}`;
      }
      if (guest_cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `guest_cnic=${guest_cnic}`;
      }
      if (date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_from=${date_from}`;
      }
      if (date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_to=${date_to}`;
      }
      if (payment_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `payment_date_from=${payment_date_from}`;
      }
      if (payment_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `payment_date_to=${payment_date_to}`;
      }
      if (mess_payment_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess_payment_date_from=${mess_payment_date_from}`;
      }
      if (mess_payment_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess_payment_date_to=${mess_payment_date_to}`;
      }
      if (check_in_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_in_date_from=${check_in_date_from}`;
      }
      if (check_in_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_in_date_to=${check_in_date_to}`;
      }
      if (check_out_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_out_date_from=${check_out_date_from}`;
      }
      if (check_out_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_out_date_to=${check_out_date_to}`;
      }
      if (mess) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess=${mess}`;
      }
      if (managing_hq) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `managing_hq=${managing_hq}`;
      }
      if (city) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `city=${city}`;
      }
      if (resolved) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `resolved=${resolved}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },
    async getFinancialCheckoutReportExcel({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/financial-checkout-report-excel-download`,
        payload,
        {
          responseType: "blob",
        }
      );
      return res;
    },
    async getFinancialCheckoutRecordExcel({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/financial-checkout-record-excel-download`,
        payload,
        {
          responseType: "blob",
        }
      );
      return res;
    },
    async getGuestCancellationReport(
      { commit },
      {
        voucher_no,
        customer_cnic,
        guest_cnic,
        date_from,
        date_to,
        mess,
        city,
        managing_hq,
        refunded,
        payment_date_from,
        payment_date_to,
        refund_date_from,
        refund_date_to,
        check_in_date_from,
        check_in_date_to,
        check_out_date_from,
        check_out_date_to,
      }
    ) {
      let url = `bms/guest-cancellation-report`;
      if (voucher_no) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `voucher_no=${voucher_no}`;
      }
      if (customer_cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `customer_cnic=${customer_cnic}`;
      }
      if (guest_cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `guest_cnic=${guest_cnic}`;
      }
      if (date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_from=${date_from}`;
      }
      if (date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_to=${date_to}`;
      }
      if (payment_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `payment_date_from=${payment_date_from}`;
      }
      if (payment_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `payment_date_to=${payment_date_to}`;
      }
      if (refund_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `refund_date_from=${refund_date_from}`;
      }
      if (refund_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `refund_date_to=${refund_date_to}`;
      }
      if (check_in_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_in_date_from=${check_in_date_from}`;
      }
      if (check_in_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_in_date_to=${check_in_date_to}`;
      }
      if (check_out_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_out_date_from=${check_out_date_from}`;
      }
      if (check_out_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_out_date_to=${check_out_date_to}`;
      }
      if (mess) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess=${mess}`;
      }
      if (managing_hq) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `managing_hq=${managing_hq}`;
      }
      if (city) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `city=${city}`;
      }
      if (refunded) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `refunded=${refunded}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },
    async getGuestCancellationReportExcel({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/guest-cancellation-report-excel-download`,
        payload,
        {
          responseType: "blob",
        }
      );
      return res;
    },
    async getGuestCancellationRecordExcel({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/guest-cancellation-record-excel-download`,
        payload,
        {
          responseType: "blob",
        }
      );
      return res;
    },
    async getEarlyCheckoutMessReport(
      { commit },
      {
        voucher_no,
        customer_cnic,
        guest_cnic,
        date_from,
        date_to,
        mess,
        city,
        managing_hq,
        resolved,
        payment_date_from,
        payment_date_to,
        mess_payment_date_from,
        mess_payment_date_to,
        check_in_date_from,
        check_in_date_to,
        check_out_date_from,
        check_out_date_to,
      }
    ) {
      let url = `bms/early-checkout-mess-report`;
      if (voucher_no) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `voucher_no=${voucher_no}`;
      }
      if (customer_cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `customer_cnic=${customer_cnic}`;
      }
      if (guest_cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `guest_cnic=${guest_cnic}`;
      }
      if (date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_from=${date_from}`;
      }
      if (date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_to=${date_to}`;
      }
      if (payment_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `payment_date_from=${payment_date_from}`;
      }
      if (payment_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `payment_date_to=${payment_date_to}`;
      }
      if (mess_payment_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess_payment_date_from=${mess_payment_date_from}`;
      }
      if (mess_payment_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess_payment_date_to=${mess_payment_date_to}`;
      }
      if (check_in_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_in_date_from=${check_in_date_from}`;
      }
      if (check_in_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_in_date_to=${check_in_date_to}`;
      }
      if (check_out_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_out_date_from=${check_out_date_from}`;
      }
      if (check_out_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_out_date_to=${check_out_date_to}`;
      }
      if (mess) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess=${mess}`;
      }
      if (managing_hq) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `managing_hq=${managing_hq}`;
      }
      if (city) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `city=${city}`;
      }
      if (resolved) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `resolved=${resolved}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },
    async getEarlyCheckoutMessReportExcel({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/early-checkout-mess-report-excel-download`,
        payload,
        {
          responseType: "blob",
        }
      );
      return res;
    },
    async getEarlyCheckoutGuestReport(
      { commit },
      {
        voucher_no,
        customer_cnic,
        guest_cnic,
        date_from,
        date_to,
        mess,
        city,
        managing_hq,
        refunded,
        payment_date_from,
        payment_date_to,
        refund_date_from,
        refund_date_to,
        check_in_date_from,
        check_in_date_to,
        check_out_date_from,
        check_out_date_to,
      }
    ) {
      let url = `bms/early-checkout-guest-report`;
      if (voucher_no) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `voucher_no=${voucher_no}`;
      }
      if (customer_cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `customer_cnic=${customer_cnic}`;
      }
      if (guest_cnic) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `guest_cnic=${guest_cnic}`;
      }
      if (date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_from=${date_from}`;
      }
      if (date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_to=${date_to}`;
      }
      if (payment_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `payment_date_from=${payment_date_from}`;
      }
      if (payment_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `payment_date_to=${payment_date_to}`;
      }
      if (refund_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `refund_date_from=${refund_date_from}`;
      }
      if (refund_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `refund_date_to=${refund_date_to}`;
      }
      if (check_in_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_in_date_from=${check_in_date_from}`;
      }
      if (check_in_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_in_date_to=${check_in_date_to}`;
      }
      if (check_out_date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_out_date_from=${check_out_date_from}`;
      }
      if (check_out_date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `check_out_date_to=${check_out_date_to}`;
      }
      if (mess) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess=${mess}`;
      }
      if (managing_hq) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `managing_hq=${managing_hq}`;
      }
      if (city) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `city=${city}`;
      }
      if (refunded) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `refunded=${refunded}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },
    async getEarlyCheckoutGuestReportExcel({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/early-checkout-guest-report-excel-download`,
        payload,
        {
          responseType: "blob",
        }
      );
      return res;
    },
    // async getEarlyCheckoutMessRecordExcel({ commit }, { payload }) {
    //   const res = await axiosIns.post(
    //     `bms/early-checkout-mess-record-excel-download`,
    //     payload,
    //     {
    //       responseType: "blob",
    //     }
    //   );
    //   return res;
    // },
    // async getEarlyCheckoutGuestRecordExcel({ commit }, { payload }) {
    //   const res = await axiosIns.post(
    //     `bms/early-checkout-guest-record-excel-download`,
    //     payload,
    //     {
    //       responseType: "blob",
    //     }
    //   );
    //   return res;
    // },
    async getAgmDashboardData(
      { commit },
      { mess, city, managing_hq, date_from, date_to }
    ) {
      let url = `bms/agm-dashboard-view`;

      if (mess) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess=${mess}`;
      }
      if (city) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `city=${city}`;
      }
      if (managing_hq) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `managing_hq=${managing_hq}`;
      }
      if (date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_from=${date_from}`;
      }
      if (date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_to=${date_to}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },
    async getMsDashboardData(
      { commit },
      { mess, city, managing_hq, date_from, date_to }
    ) {
      let url = `bms/ms-dashboard-view`;

      if (mess) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `mess=${mess}`;
      }
      if (city) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `city=${city}`;
      }
      if (managing_hq) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `managing_hq=${managing_hq}`;
      }
      if (date_from) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_from=${date_from}`;
      }
      if (date_to) {
        if (url.includes("?")) {
          url += `&`;
        } else {
          url += `?`;
        }
        url += `date_to=${date_to}`;
      }

      const res = await axiosIns.get(url);
      return res;
    },

    // Excel Download
    async createExcel({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/excel-download`,
        payload,
        {
          responseType: "blob",
        }

        // headers: {"Accept": "application/vnd.ms-excel"},
      );
      return res;
    },
    async refundBookingsFromExcel({ commit }, formData) {
      const res = await axiosIns.post(
        "bms/refund-booking-from-excel",
        formData
      );
      return res;
    },
    async messPaymentsFromExcel({ commit }, formData) {
      const res = await axiosIns.post("bms/mess-payments-from-excel", formData);
      return res;
    },
    async earlyCheckoutRefundBookingsFromExcel({ commit }, formData) {
      const res = await axiosIns.post(
        "bms/early-checkout-refund-bookings-from-excel",
        formData
      );
      return res;
    },
    async earlyCheckoutMessPaymentsFromExcel({ commit }, formData) {
      const res = await axiosIns.post(
        "bms/early-checkout-mess-payments-from-excel",
        formData
      );
      return res;
    },
    async getCityCategories({ commit }, { pageNumber, name }) {
      let url = `bms/city-categories/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getCityCategory({ commit }, { pk }) {
      const res = await axiosIns.get(`bms/city-categories/${pk}/`);
      return res;
    },
    async createCityCategory({ commit }, payload) {
      const res = await axiosIns.post("bms/city-categories/", payload);
      return res;
    },
    async updateCityCategory({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/city-categories/${pk}/`, payload);
      return res;
    },
    async deleteCityCategory({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/city-categories/${pk}/`);
      return res;
    },
    async getCityCategoriesUnpaginated() {
      let url = `bms/city-categories-unpaginated`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getCityCategoryPrices({ commit }, { city_category }) {
      let url = `bms/city-category-prices`;
      if (city_category) {
        url += `?city_category=${city_category}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async calculateBookingPayment({ commit }, payload) {
      const res = await axiosIns.post("bms/calculate-total-payment", payload);
      return res;
    },
    async checkoutBooking({ commit }, payload) {
      const res = await axiosIns.post("bms/booking-checkout", payload);
      return res;
    },

    async customerBookingFeedback({ commit }, payload) {
      let url = `bms/room-feedback-user`;
      const res = await axiosIns.post(url, payload);
      return res;
    },
    async getRoomsAvailabilityReport({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/rooms-availability-in-mess`,
        payload
      );
      return res;
    },
    async roomsAvailabilityReportFromExcel({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/rooms-availability-mess-report-excel-download`,
        payload,
        {
          responseType: "blob",
        }
      );
      return res;
    },
    async getRoomFeedback({ commit }, { pageNumber }) {
      let url = `bms/room-feedback/?page=${pageNumber}`;
      const res = await axiosIns.get(url);
      return res;
    },

    async getModules({ commit }, { pageNumber, name }) {
      let url = `modules/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    async getModulesUnpaginated() {
      const res = await axiosIns.get("modules-unpaginated");
      return res;
    },

    async createModule({ commit }, payload) {
      const res = await axiosIns.post("modules/", payload);
      return res;
    },
    async updateModule({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`modules/${pk}/`, payload);
      return res;
    },

    async deleteModule({ commit }, { pk }) {
      const res = await axiosIns.delete(`modules/${pk}/`);
      return res;
    },

    async sendSMS({ commit }, payload) {
      const res = await axiosIns.post("send-sms", payload);
      return res
    },
    async downloadMessesReport({ commit }, { payload }) {
      const res = await axiosIns.post(
        `bms/messes-download-excel`,
        payload,
        {
          responseType: "blob",
        }
      );
      return res;
    },

    async getRoomUnavailabilityDates(
      { commit },
      {
        pageNumber,
        room,
        mess,
        date,
        date_from,
        date_to
      }
    ) {
      let url = `bms/room-unavailability-dates/?page=${pageNumber}`;
      if (room) {
        url += `&room=${room}`;
      }
      if (mess) {
        url += `&mess=${mess}`;
      }
      if (date) {
        url += `&date=${date}`;
      }
      if (date_from) {
        url += `&date_from=${date_from}`;
      }
      if (date_to) {
        url += `&date_to=${date_to}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async createRoomUnavailabilityDate({ commit }, payload) {
      const res = await axiosIns.post("bms/room-unavailability-dates/", payload);
      return res;
    },

    async updateRoomUnavailabilityDate({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/room-unavailability-dates/${pk}/`, payload);
      return res;
    },

    async deleteRoomUnavailabilityDate({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/room-unavailability-dates/${pk}/`);
      return res;
    },
    async getMessUnavailabilityDates(
      { commit },
      {
        pageNumber,
        mess,
        date,
        date_from,
        date_to
      }
    ) {
      let url = `bms/mess-unavailability-dates/?page=${pageNumber}`;
      if (mess) {
        url += `&mess=${mess}`;
      }
      if (date) {
        url += `&date=${date}`;
      }
      if (date_from) {
        url += `&date_from=${date_from}`;
      }
      if (date_to) {
        url += `&date_to=${date_to}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async createMessUnavailabilityDate({ commit }, payload) {
      const res = await axiosIns.post("bms/mess-unavailability-dates/", payload);
      return res;
    },

    async updateMessUnavailabilityDate({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/mess-unavailability-dates/${pk}/`, payload);
      return res;
    },

    async deleteMessUnavailabilityDate({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/mess-unavailability-dates/${pk}/`);
      return res;
    },
    async getCityUnavailabilityDates(
      { commit },
      {
        pageNumber,
        city,
        date,
        date_from,
        date_to
      }
    ) {
      let url = `bms/city-unavailability-dates/?page=${pageNumber}`;
      if (city) {
        url += `&city=${city}`;
      }
      if (date) {
        url += `&date=${date}`;
      }
      if (date_from) {
        url += `&date_from=${date_from}`;
      }
      if (date_to) {
        url += `&date_to=${date_to}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },

    async createCityUnavailabilityDate({ commit }, payload) {
      const res = await axiosIns.post("bms/city-unavailability-dates/", payload);
      return res;
    },

    async updateCityUnavailabilityDate({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`bms/city-unavailability-dates/${pk}/`, payload);
      return res;
    },

    async deleteCityUnavailabilityDate({ commit }, { pk }) {
      const res = await axiosIns.delete(`bms/city-unavailability-dates/${pk}/`);
      return res;
    },

    // async updateBankAccount({ commit }, { payload, pk }) {
    //   const res = await axiosIns.patch(`bms/bank-accounts/${pk}/`, payload);
    //   return res;
    // },

  },
};
