import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import vSelect from "vue-select";
import VueCompositionAPI from "@vue/composition-api";
import VueMq from 'vue-mq'

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import VueScrollactive from "vue-scrollactive";
import DynamicMarquee from "vue-dynamic-marquee";
import i18n from "@/libs/i18n";
import Lightbox from "vue-my-photos";
// BSV Registration
Vue.use(BootstrapVue);

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 450,
    md: 1250,
    lg: Infinity,
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})


// Composition API
Vue.use(VueCompositionAPI);

Vue.use(VueScrollactive);
Vue.component("dynamic-marquee", DynamicMarquee);

//VueSelect
Vue.component("v-select", vSelect);

// img gallery
Vue.component("lightbox", Lightbox);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

require("vue-select/dist/vue-select.css");

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

require("@core/scss/vue/pages/page-auth.scss");
require("@core/scss/vue/libs/vue-wizard.scss");
require("@core/scss/vue/libs/vue-select.scss");

Vue.config.productionTip = false;

function checkAuth() {
  return new Promise(async (resolve) => {
    try {
      await store.dispatch("appData/getCurrentUser");
      resolve();
    } catch (error) {
      resolve();
    }
  });
}

function createApp() {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}

function createBeforeEach() {
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.getters["appData/isLoggedIn"]) {
        let allowAccess = false
        if (to.meta.permission) {
          if (store.getters["appData/hasPermission"](to.meta.permission)) {
            next();
          }
          else {
            if (to.name !== 'Home') {
              next({ name: "Home" });
            }
          }
        }
        if (store.getters["appData/getUser"].role_data.code_name == 'su') {
          next();
        }
        if (to.meta.block_field) {
          if (store.getters["appData/getUser"][to.meta.block_field]) {
            // if (to.name !== 'Home') {
            //   next({ name: "Home" });
            // }
            return
          }
        }
        if (to.meta.roles_allowed) {
          if (to.meta.roles_allowed.includes(store.getters["appData/getUser"].role_data.code_name)) {
            // next();
            allowAccess = true
          }
          else {
            if (to.name !== 'Home') {
              next({ name: "Home" });
            }
            // return
          }
        }
        // start
        if (store.getters["appData/getUser"].role_data.code_name == 'multiple_landing_dashboard') {
          next();
          allowAccess = true
          if (to.name !== 'LandingPage') {
            next({ name: "LandingPage" });
          }
        }
        // end
        if (to.meta.user_types_allowed) {
          if (to.meta.user_types_allowed.includes(store.getters["appData/getUser"].user_type)) {
            next();
          }
          else {
            if (to.name !== 'Home') {
              next({ name: "Home" });
            }
            // return
          }
        }
        if (allowAccess = true) {
          next();
        }
        if (!to.meta.permission && !to.meta.roles_allowed && !to.meta.user_types_allowed) {
          next();
        }
        // else {
        //   next();
        // }
      } else {
        let previousRoleCode = localStorage.getItem("previous_user_role");
        if (previousRoleCode) {
          if (previousRoleCode.includes('bk__')) {
            localStorage.removeItem("previous_user_role")
            next({ name: "BookingLogin" });
          }
          else {
            localStorage.removeItem("previous_user_role")
            next({ name: "Login" });
          }
        }
        else {
          next({ name: "Login" });
        }
        // if (to.name !== 'Home') {
        //   next({ name: "Home" });
        // }
      }
    } else {
      next();
    }
    // if (
    //   !to.matched.some((record) => record.meta.requiresAuth) &&
    //   store.getters["appData/isLoggedIn"]
    // ) {
    //   next({ name: "Home" });
    // } else {
    //   next();
    // }
  });
}




// function createBeforeEach() {
//   router.beforeEach((to, from, next) => {
//     if (from.path === to.path) {
//       next();
//     } else {
//       if (to.matched.some((record) => record.meta.requiresAuth)) {
//         if (store.getters["appData/isLoggedIn"]) {
//           if (to.meta.permission) {
//             if (store.getters["appData/hasPermission"](to.meta.permission)) {
//               next();
//               return;
//             } else {
//               next({ name: "Home" });
//               return; 
//             }
//           } else {
//             next();
//             return; 
//           }
//         } else {
//           next({ name: "Home" });
//           return;
//         }
//       } else {
//         next();
//         return; 
//       }
//     }
//   });
// }


// function createBeforeEach() {
//   router.beforeEach((to, from, next) => {
//     if (from.path === to.path) {
//       next();
//     } else {
//       if (to.matched.some((record) => record.meta.requiresAuth)) {
//         if (store.getters["appData/isLoggedIn"]) {
//           if (to.meta.permission) {
//             if (store.getters["appData/hasPermission"](to.meta.permission)) {
//               next();
//             } else {
//               next({ name: "Home" });
//             }
//           } else {
//             next();
//           }
//         } else {
//           next({ name: "Home" });
//         }
//       } else {
//         next();
//       }
//       if (
//         !to.matched.some((record) => record.meta.requiresAuth) &&
//         store.getters["appData/isLoggedIn"]
//       ) {
//         next({ name: "Home" });
//       } else {
//         next();
//       }
//     }
//   });
// }

checkAuth().then(() => {
  createBeforeEach();
  createApp();
});
