// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {};

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {};

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: "Facilitation Portal", // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: require("@/assets/images/logo/afp-logo.png"), // Will update logo in navigation menu (Branding)
    appColor: "#aec2b6",
    // appColor: "#094120
    petitionAppName: "Facilitation Portal",
    petitionAppLogoImage: require("@/assets/images/logo/afp-logo.png"),
    shuhadaAppName: "Shuhada",
    shuhadaAppLogoImage: require("@/assets/images/logo/afp-logo.png"),
    NocAppName: "Facilitation Portal",
    NocAppLogoImage: require("@/assets/images/logo/afp-logo.png"),
    adminAppName: "Facilitation Portal",
    adminAppLogoImage: require("@/assets/images/logo/afp-logo.png"),
    feedbackAppName: "Military Hospitals",
    feedbackAppLogoImage: require("@/assets/images/logo/CNE.png"),
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    routerTransition: "zoom-fade", // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: "vertical", // vertical, horizontal
    contentWidth: "full", // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "floating", // static , sticky , floating, hidden
      backgroundColor: "", // BS color options [primary, success, etc]
    },
    footer: {
      type: "sticky", // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true,
  },
};
